import { Component, Input, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FileExplorerRouteComponent } from '../../component/file-explorer/route/file-explorer-route.component';

import { FileService } from '../../services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/authentication/services/user.service';

import { FileElement } from '../../models/element';
import { FileChooserService } from '../../services/file-chooser.service';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent
{
  // Variables
  public fileElements: Observable<FileElement[]>;
  public fileId: string;
  public fileName: string;
  public selectedFileName: string;

  // ---------- TODO: Assign ID and Token Variables to custom fields of user ---------- //
  private userID: string;
  private userToken: string;

  @Input()
  requiredFileType: string;
  uploadProgress: boolean;
  folderName: string;
  folderExists: boolean = false;
  error: boolean;
  errorSize: boolean;

  constructor(
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    public fileService: FileService,
    private http: HttpClient,
    public sharedService: SharedService,
    private fileRoute: FileExplorerRouteComponent,
    private userService: UserService,
    private fileChooserService: FileChooserService,
    private trackerService: TrackerService
  ) { }

  // OnInit Function
  ngOnInit(): void { }

  cancelDialog()
  {
    this.dialogRef;
  }

  cancelUpload()
  {
    this.fileService.cancelUpload();
  }

  /*---------------------------------------- UPLOAD DIALOG FUNCTIONS START ----------------------------------------*/
  // run when file in FileChooser is selected
  async onFileSelected(event: any)
  {
    this.uploadProgress = true;
    await this.fileChooserService.fileSelect(event).then(() =>
    {
      this.uploadProgress = false;
      this.fileRoute.updateFileElementQuery();
      this.dialogRef.close();
    })
  }

  // run when file in FileChooser is selected
  async onFileDropped(event: any)
  {
    this.uploadProgress = true;
    await this.fileChooserService.fileDrop(event).then(() =>
    {
      this.uploadProgress = false;
      this.fileRoute.updateFileElementQuery();
      this.dialogRef.close();
    })
  }
  /*---------------------------------------- UPLOAD DIALOG FUNCTIONS END ----------------------------------------*/
}
