export class UserTracking
{
    filesUploaded: number
    filesDownloaded: number
    filesDeleted: number
    foldersUploaded: number
    foldersDownloaded: number
    foldersDeleted: number
    clicks: {
        File: number
        Shared: number
        Favorite: number
        Trash: number
    }
    active: {
        File: number
        Shared: number
        Favorite: number
        Trash: number
        Types: {
            pdf: number
            doc: number
            xls: number
            ppt: number
            jpg: number
            png: number
            gif: number
            txt: number
            other: number
        }
    }
}
