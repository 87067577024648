<!-- Toolbar above the Explorer Content -->
<mat-toolbar>
    <mat-toolbar-row>
        <!--<button mat-icon-button (click)="toggleSidenav()"><mat-icon>menu</mat-icon></button>-->
        <span style="margin-left: 8px" class="pointer">Geteilt</span>
        <span *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">{{path || 'Geteilt'}}</span>
        <span class="vertical-spacer"></span>
    </mat-toolbar-row>
    <mat-toolbar-row>
        <div class="search-block content-module">
            <mat-icon class="fa-light fa-magnifying-glass"></mat-icon>
            <input class="search-control content-module input" type="text" placeholder="Suchen" [(ngModel)]="searchText"
                #searchbar>
        </div>
        <span class="vertical-spacer"></span>
        <app-sort-menu (sort)="setCurrentSort($event)"></app-sort-menu>
        <button *ngIf="this.viewMode == 'list' " mat-icon-button id="view_module" (click)="toggleView('module')"
            aria-label="view_module">
            <mat-icon class="fa-light fa-list"></mat-icon>
        </button>
        <button *ngIf="this.viewMode == 'module'" mat-icon-button id="view_list" (click)="toggleView('list')"
            aria-label="view_list">
            <mat-icon class="fa-light fa-grid"></mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>


<!-- Generate Explorer Content for Elements -->
<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-between stretch">
    <!-- VIEWMODE MODULE -->
    <div *ngIf="this.viewMode == 'module'" class="content-module" fxFlex fxLayout="row"
        (window:resize)="onResize($event)">
        <mat-grid-list [cols]="breakpoint" rowHeight="100px" fxFlex>
            <div *ngIf="fileElements != null">
                <span *ngIf="fileElements.length == 0" class="emptyTitle">Keine
                    geteilten Dateien</span>
            </div>
            <mat-grid-tile
                *ngFor="let element of fileElements | filter: searchText | sort: this.currentSortDirection : this.currentSortMode"
                class=file-or-folder title={{element.name}}>
                <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
                    #menuTrigger="matMenuTrigger"></span>
                <div class="icons pointer" *ngIf="element.isFolder; else downloadLinkModule" (click)="navigate(element)"
                    (contextmenu)="openMenu($event, menuTrigger)">
                    <div matListItemIcon>
                        <img width="48" src="assets/icons/file-type-folder.svg">
                    </div>
                    <span>{{element.name}}</span>
                </div>

                <ng-template #downloadLinkModule>
                    <div class="icons pointer" (click)="openMenu($event, menuTrigger)"
                        (contextmenu)="openMenu($event, menuTrigger)">
                        <div [ngSwitch]="element.file_type">
                            <img *ngSwitchCase="'application/pdf'" src="assets/icons/file-type-pdf-adobe-acrobat.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/x-zip-compressed'"
                                src="assets/icons/file-type-zip-compressed.svg" matListItemIcon>
                            <img *ngSwitchCase="'application/zip'" src="assets/icons/file-type-zip-compressed.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/zip'" src="assets/icons/file-type-zip-compressed.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/msword'" src="assets/icons/file-type-doc-word-document.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                src="assets/icons/file-type-doc-word-document.svg" matListItemIcon>
                            <img *ngSwitchCase="'text/css'" src="assets/icons/file-type-css-styles.svg" matListItemIcon>
                            <img *ngSwitchCase="'text/plain'" src="assets/icons/file-type-txt-text-textedit.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'image/jpeg'" src="assets/icons/file-type-jpg-jpeg-image.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'image/png'" src="assets/icons/file-type-png-image.svg" matListItemIcon>
                            <img *ngSwitchCase="'video/x-msvideo'" src="assets/icons/file-type-avi-video-movie.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/x-msdownload'||'application/octet-stream'"
                                src="assets/icons/file-type-dll.svg" matListItemIcon>
                            <img *ngSwitchCase="'application/postscript'" src="assets/icons/file-type-eps.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'text/html'" src="assets/icons/file-type-htm-web-page.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'video/quicktime'" src="assets/icons/file-type-mov-movie-video.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'audio/mpeg'" src="assets/icons/file-type-mp3-audio-music.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/vnd.ms-powerpoint'"
                                src="assets/icons/file-type-ppt-powerpoint.svg" matListItemIcon>
                            <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                                src="assets/icons/file-type-ppt-powerpoint.svg" matListItemIcon>
                            <img *ngSwitchCase="'image/vnd.adobe.photoshop'"
                                src="assets/icons/file-type-psd-adobe-photoshop.svg" matListItemIcon>
                            <img *ngSwitchCase="'audio/x-wav'" src="assets/icons/file-type-wav-wave-music-audio.svg"
                                matListItemIcon>
                            <img *ngSwitchCase="'application/vnd.ms-excel'"
                                src="assets/icons/file-type-xls-excel-microsoft.svg" matListItemIcon>
                            <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                src="assets/icons/file-type-xls-excel-microsoft.svg" matListItemIcon>
                            <img *ngSwitchDefault width="48" src="assets/icons/file-type-unknown-question-mark.svg"
                                matListItemIcon>
                        </div>
                        <span>{{element.name}}</span>
                    </div>
                </ng-template>
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <!-- VIEWMODE LIST -->
    <div *ngIf="this.viewMode == 'list'" class="content-list" fxFlex fxLayout="column">
        <div *ngIf="fileElements != null">
            <span *ngIf="fileElements.length == 0" style="font-size: 50px; display: table; margin: 50px auto;">Keine
                geteilten Dateien</span>
        </div>
        <mat-list>
            <div class="listItem"
                *ngFor="let element of fileElements | filter: searchText | sort: this.currentSortDirection : this.currentSortMode">
                <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
                    #menuTrigger="matMenuTrigger"></span>
                <mat-list-item class="list-item-alignment" *ngIf="element.isFolder; else downloadLinkList"
                    (click)="navigate(element)" (contextmenu)="openMenu($event, menuTrigger)">
                    <div matListItemIcon style="margin-bottom: 25px;">
                        <img width="48" src="assets/icons/file-type-folder.svg">
                    </div>
                    <span matListItemTitle>{{element.name}}</span>
                </mat-list-item>

                <ng-template #downloadLinkList>
                    <mat-list-item class="list-item-alignment" (click)="openMenu($event, menuTrigger)"
                        (contextmenu)="openMenu($event, menuTrigger)">
                        <div [ngSwitch]="element.file_type" matListItemIcon>
                            <img *ngSwitchCase="'application/pdf'" width="48"
                                src="assets/icons/file-type-pdf-adobe-acrobat.svg">
                            <img *ngSwitchCase="'application/x-zip-compressed'" width="48"
                                src="assets/icons/file-type-zip-compressed.svg">
                            <img *ngSwitchCase="'application/zip'" width="48"
                                src="assets/icons/file-type-zip-compressed.svg">
                            <img *ngSwitchCase="'application/zip'" width="48"
                                src="assets/icons/file-type-zip-compressed.svg">
                            <img *ngSwitchCase="'application/msword'" width="48"
                                src="assets/icons/file-type-doc-word-document.svg">
                            <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                                width="48" src="assets/icons/file-type-doc-word-document.svg">
                            <img *ngSwitchCase="'text/css'" width="48" src="assets/icons/file-type-css-styles.svg">
                            <img *ngSwitchCase="'text/plain'" width="48"
                                src="assets/icons/file-type-txt-text-textedit.svg">
                            <img *ngSwitchCase="'image/jpeg'" width="48"
                                src="assets/icons/file-type-jpg-jpeg-image.svg">
                            <img *ngSwitchCase="'image/png'" width="48" src="assets/icons/file-type-png-image.svg">
                            <img *ngSwitchCase="'video/x-msvideo'" width="48"
                                src="assets/icons/file-type-avi-video-movie.svg">
                            <img *ngSwitchCase="'application/x-msdownload'||'application/octet-stream'" width="48"
                                src="assets/icons/file-type-dll.svg">
                            <img *ngSwitchCase="'application/postscript'" width="48"
                                src="assets/icons/file-type-eps.svg">
                            <img *ngSwitchCase="'text/html'" width="48" src="assets/icons/file-type-htm-web-page.svg">
                            <img *ngSwitchCase="'video/quicktime'" width="48"
                                src="assets/icons/file-type-mov-movie-video.svg">
                            <img *ngSwitchCase="'audio/mpeg'" width="48"
                                src="assets/icons/file-type-mp3-audio-music.svg">
                            <img *ngSwitchCase="'application/vnd.ms-powerpoint'" width="48"
                                src="assets/icons/file-type-ppt-powerpoint.svg">
                            <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                                width="48" src="assets/icons/file-type-ppt-powerpoint.svg">
                            <img *ngSwitchCase="'image/vnd.adobe.photoshop'" width="48"
                                src="assets/icons/file-type-psd-adobe-photoshop.svg">
                            <img *ngSwitchCase="'audio/x-wav'" width="48"
                                src="assets/icons/file-type-wav-wave-music-audio.svg">
                            <img *ngSwitchCase="'application/vnd.ms-excel'" width="48"
                                src="assets/icons/file-type-xls-excel-microsoft.svg">
                            <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                width="48" src="assets/icons/file-type-xls-excel-microsoft.svg">
                            <img *ngSwitchDefault width="48" src="assets/icons/file-type-unknown-question-mark.svg">
                        </div>
                        <span matListItemTitle>{{element.name}}</span>
                        <span matListItemLine>
                            Änderungsdatum: {{element.lastModified | date:'dd MMMM yyyy HH:mm'}}
                        </span>
                        <span matListItemLine *ngIf="element.file_size < 1000">Größe: 1kb</span>
                        <span matListItemLine *ngIf="element.file_size >= 1000 && element.file_size <= 1000000">Größe:
                            {{math.round(element.file_size/1000)}}kb</span>
                        <span matListItemLine
                            *ngIf="element.file_size >= 1000000 && element.file_size <= 1000000000">Größe:
                            {{math.round(element.file_size/1000000)}}mb</span>
                        <span matListItemLine *ngIf="element.file_size >= 1000000000">Größe:
                            {{math.round(element.file_size/1000000000)}}gb</span>
                    </mat-list-item>
                </ng-template>

            </div>
        </mat-list>
    </div>
</div>


<!-- Declare Menu for RightClick on Element -->
<mat-menu class="matMenu" #rootMenu="matMenu" [overlapTrigger]="false">
    <ng-template matMenuContent let-element="element">
        <!-- "Download" Button for RightClickMenu -->
        <button *ngIf="element.isFolder == false" mat-menu-item (click)="download(element)">
            <mat-icon class="fa-light fa-file-arrow-down"></mat-icon>
            <span>Download</span>
        </button>
        <button *ngIf="element.isFolder == true" mat-menu-item (click)="downloadFolder(element)">
            <mat-icon class="fa-light fa-file-arrow-down"></mat-icon>
            <span>Download</span>
        </button>
        <!-- "Remove" Button for RightClickMenu -->
        <!-- <button mat-menu-item (click)="removeElement(element)">
            <mat-icon class="fa-light fa-trash"></mat-icon>
            <span>Löschen</span>
        </button> -->
    </ng-template>
</mat-menu>