import { WebCryptoRng } from '../util/web-crypto-rng';
import { WebCryptoKey } from '../webCryptoKey';

/**
 * AsymmetricWebCryptoKey provides a generateWrappingKey function and an export function for asymmetric keys.
 */
export class AsymmetricWebCryptoKey extends WebCryptoKey {
    constructor(key: CryptoKey) {
        super(key);
    }

    /**
     * Exports the cryptoKey in an external, portable format.
     *
     * Optional wrappingKey encrypts ("wraps") the cryptoKey.
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/wrapKey
     *
     * @param wrappingKey (optional) wrappingKey encrypts the cryptoKey (e.g. pek)
     * @returns exported key (optionally wrapped by wrappingKey with IV)
     */
    public async export(wrappingKey?: WebCryptoKey): Promise<ArrayBuffer> {
        if (wrappingKey) {
            const iv = WebCryptoRng.getRandomValues(WebCryptoKey.IV_LENGTH);
            const algorithm: AesGcmParams = {
                name: `AES-GCM`,
                iv: iv,
            };
            const wrappedKey = await crypto.subtle.wrapKey(
                'jwk',
                this.cryptoKey,
                wrappingKey?.cryptoKey,
                algorithm
            );
            const wrappedKeyWithIv = this.joinArrayBuffer(iv, wrappedKey);

            return Promise.resolve(wrappedKeyWithIv); // [IV | EncryptedKey]
        } else {
            return crypto.subtle.exportKey('raw', this.cryptoKey);
        }
    }
}
