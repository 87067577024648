import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit
{
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router) { }

  async ngOnInit()
  {
    // check auth_code in url
    const auth_code = this.route.snapshot.queryParams['code'];
    localStorage.setItem('auth_code', auth_code);

    // if auth_code exists, get access_token
    if (auth_code)
    {
      // get access_token
      const access_token = await this.getAccessToken(auth_code);
      // set access_token and callbackRunning in local storage
      localStorage.setItem('access_token', access_token.access_token);
      localStorage.setItem('callbackRunning', "false");
      // set toggleTheme in local storage
      if (localStorage.getItem('toggleTheme') == null)
      {
        localStorage.setItem('toggleTheme', "Light");
      }
    }
    // if auth_code does not exist, navigate to homepage
    this.router.navigate(['']);

    // get user data
    await this.userService.getUserID();
  }

  async getAccessToken(auth_code: string): Promise<any>
  {
    // get access_token from authService
    const response = await this.authService.getAccessToken(auth_code);
    return response;
  }
}