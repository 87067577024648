/**
 * Random number generator (RNG) - Generating of random values
 */
export class WebCryptoRng {
    /**
     * Get random values.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues
     * Is used to generate e.g. an iv (= initialization vector).
     * @param count specify the number of random values
     * @returns a definded number of random numbers
     */
    public static getRandomValues(count: number): Uint8Array {
        return crypto.getRandomValues(new Uint8Array(count));
    }
}
