<mat-tab-group class="tab-group" animationDuration="0ms" (selectedTabChange)="onTabClick($event)">
  <mat-tab>
    <ng-template class="tab-header" mat-tab-label>
      <mat-icon class="fa-light fa-folders tab-icon"></mat-icon>
      <span class="tab-label">Meine Dateien</span>
    </ng-template>
    <ng-template class="tab-content" matTabContent>
      <!-- File Explorer Start -->
      <file-explorer [fileElements]="fileElements | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
        [viewMode]="viewMode" (folderAdded)="addFolder($event)" (elementFavorite)="favoriteElement($event)"
        (trashElement)="trashElement($event)" (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
        (elementRenamed)="renameElement($event)" (elementMoved)="moveElement($event)"
        (viewToggled)="toggleView($event)">
      </file-explorer>
      <!-- File Explorer End -->
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="fa-light fa-share-nodes tab-icon"></mat-icon>
      <span class="tab-label">Geteilt</span>
    </ng-template>
    <ng-template matTabContent>
      <!-- Geteilt Start -->
      <app-shared [fileElements]="sharedFileElements | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
        [viewMode]="viewMode" (trashElement)="trashElement($event)" (navigatedDown)="navigateToFolder($event)" (navigatedUp)="navigateUp()"
        (viewToggled)="toggleView($event)">
      </app-shared>
      <!-- Geteilt End -->
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="fa-light fa-star tab-icon"></mat-icon>
      <span class="tab-label">Favoriten</span>
    </ng-template>
    <ng-template matTabContent>
      <!-- Favorites Start -->
      <app-favorites [fileElements]="favFileElements | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
        [viewMode]="viewMode" (trashElement)="trashElement($event)" (navigatedDown)="navigateToFolder($event)"
        (navigatedUp)="navigateUp()" (elementRemoveFavorite)="removeFavoriteElement($event)"
        (viewToggled)="toggleView($event)">
      </app-favorites>
      <!-- Favorites End -->
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="fa-light fa-trash tab-icon"></mat-icon>
      <span class="tab-label">Papierkorb</span>
    </ng-template>
    <ng-template matTabContent>
      <!-- Trash Start -->
      <app-trash [fileElements]="trashFileElements | async" [path]="currentPath" [canNavigateUp]="canNavigateUp"
        [viewMode]="viewMode" (elementRecovered)="recoverElement($event)" (navigatedDown)="navigateToFolder($event)"
        (navigatedUp)="navigateUp()" (elementRemoved)="removePermanentElement($event)"
        (emptyTrashElements)="emptyTrash($event)" (viewToggled)="toggleView($event)">
      </app-trash>
      <!-- Trash End -->
    </ng-template>
  </mat-tab>
</mat-tab-group>