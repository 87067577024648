import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileService } from '../../services/file.service';
import { FileElement } from '../../models/element';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss']
})
export class TrashComponent
{
  public math = Math;
  public currentSortMode: string = localStorage.getItem('sortMode') || 'name';
  public currentSortDirection: string = localStorage.getItem('sorDirection') || 'ASC';
  breakpoint: number;

  constructor(
    public fileService: FileService,
  ) { }

  @Input() fileElements: FileElement[]
  @Input() canNavigateUp: boolean
  @Input() path: string
  @Input() searchText: string;
  @Input() viewMode: string;

  @Output() navigatedDown = new EventEmitter<FileElement>()
  @Output() navigatedUp = new EventEmitter()
  @Output() elementRemoved = new EventEmitter<FileElement>()
  @Output() elementRecovered = new EventEmitter<FileElement>()
  @Output() emptyTrashElements = new EventEmitter<FileElement[]>()
  @Output() viewToggled = new EventEmitter<{ view: string }>()

  ngOnInit()
  {
    this.breakpoint = (window.innerWidth <= 400) ? 2 : (window.innerWidth <= 767 && window.innerWidth >= 400) ? 3 : 8;
  }

  onResize(event)
  {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : (event.target.innerWidth <= 767 && event.target.innerWidth >= 400) ? 3 : 8;
  }

  /*---------------------------------------- FILE/FOLDER HANDLING START ----------------------------------------*/

  // Set elementRemoved for Output
  emptyTrash() 
  {
    this.emptyTrashElements.emit(this.fileElements);
  }

  // Set elementRecovered for Output
  recoverElement(element: FileElement) 
  {
    this.elementRecovered.emit(element);
  }

  // Set elementRemoved for Output
  deleteElement(element: FileElement) 
  {
    this.elementRemoved.emit(element);
  }

  setCurrentSort(currentSort: { sortMode: string, sortDirection: string })
  {
    this.currentSortMode = currentSort.sortMode;
    this.currentSortDirection = currentSort.sortDirection;
  }

  /*---------------------------------------- FILE/FOLDER HANDLING END ----------------------------------------*/

  /*---------------------------------------- NAVIGATION START ----------------------------------------*/

  // Set navigatedDown for Output
  navigate(element: FileElement) 
  {
    if (element.isFolder)
    {
      this.navigatedDown.emit(element);
    }
  }
  // Set navigatedDown for Output
  navigateToRoot() 
  {
    this.navigatedDown.emit(this.fileService.get('root'));
  }
  // Set navigatedUp for Output
  navigateUp() 
  {
    this.navigatedUp.emit();
  }

  /*---------------------------------------- NAVIGATION END ----------------------------------------*/

  /*---------------------------------------- MENU HANDLING START ----------------------------------------*/

  // open menu by MouseEvent
  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) 
  {
    event.preventDefault();
    viewChild.openMenu();
  }

  /*---------------------------------------- MENU HANDLING END ----------------------------------------*/

  public async toggleView(view: string)
  {
    if (view === 'list')
    {
      localStorage.setItem("viewMode", "list");
      this.viewToggled.emit({ view: "list" });
    } else
    {
      localStorage.setItem("viewMode", "module");
      this.viewToggled.emit({ view: "module" });
    }
  }

  /* Not in use
  toggleSidenav()
  {
    this.sidenav.toggle();
  } 
  */
}
