import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { FileExplorerModule } from './file-explorer/module/file-explorer.module';
import { LoginModule } from './authentication/module/login.module';

import { AppComponent } from './app.component';

import { FileService } from './file-explorer/services/file.service';
import { SharedService } from './services/shared.service';
import { AuthService } from './authentication/services/auth.service';
import { MaterialModule } from './material/material.module';
import { FileChooserService } from './file-explorer/services/file-chooser.service';
import { TrackerService } from './services/tracker.service';


//Declares Components and Modules for Use
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FileExplorerModule,
    FlexLayoutModule,
    HttpClientModule,
    LoginModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  providers: [
    FileService,
    SharedService,
    AuthService,
    FileChooserService,
    TrackerService
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
