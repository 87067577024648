import { Injectable } from '@angular/core';
import { UserService } from '../authentication/services/user.service';
import { UserTracking } from '../file-explorer/models/user-tracking';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class TrackerService
{
  //Variables
  private userID: string;
  private userToken: string;
  private userTracking: UserTracking = new UserTracking();
  private trackingLoaded: boolean = false;

  //API URL for Tracking
  private trackerApiURL: string = "/api/tracking";

  constructor(
    private http: HttpClient,
    private userService: UserService
  ) { }

  /*---------------------------------------- TRACKING EVENT START ----------------------------------------*/
  public async logEvent(eventName: string, eventAction: string, counter?: string)
  {
    // Check if user tracking is loaded
    await this.checkUserTracking();

    switch (eventAction)
    {
      // Upload Tracking
      case "Uploaded":
        switch (eventName)
        {
          case "File":
            this.userTracking.filesUploaded++;
            //console.log('Files uploaded: ', this.userTracking.filesUploaded);
            break;
          case "Folder":
            this.userTracking.foldersUploaded++;
            //console.log('Folder uploaded: ', this.userTracking.foldersUploaded);
            break;
          default:
        }
        break;
      // Download Tracking
      case "Downloaded":
        switch (eventName)
        {
          case "File":
            this.userTracking.filesDownloaded++;
            //console.log('Files downloaded: ', this.userTracking.filesDownloaded);
            break;
          case "Folder":
            this.userTracking.foldersDownloaded++;
            //console.log('Folders downloaded: ', this.userTracking.foldersDownloaded);
            break;
          default:
        }
        break;
      // Delete Tracking
      case "Deleted":
        switch (eventName)
        {
          case "File":
            this.userTracking.filesDeleted++;
            //console.log('Tracking: ', 'send', 'event', eventName, eventAction, counter);
            break;
          case "Folder":
            this.userTracking.foldersDeleted++;
            //console.log('Tracking: ', 'send', 'event', eventName, eventAction, counter);
            break;
          default:
        }
        break;
      // Click Tracking
      case "Clicks":
        switch (eventName)
        {
          case "File":
            this.userTracking.clicks.File++;
            //this.logClickEvent("File");
            //console.log('Click Tracking: ', eventName);
            break;
          case "Shared":
            this.userTracking.clicks.Shared++;
            //this.logClickEvent("Shared");
            //console.log('Click Tracking: ', eventName);
            break
          case "Favorite":
            this.userTracking.clicks.Favorite++;
            //this.logClickEvent("Favorite");
            //console.log('Click Tracking: ', eventName);
            break;
          case "Trash":
            this.userTracking.clicks.Trash++;
            //this.logClickEvent("Trash");
            //console.log('Click Tracking: ', eventName);
            break;
          default:
            console.error('Click tracking could not be assigned!');
            break;
        }
        break;
      // Default
      default:
        console.error('Tracking could not be assigned!');
        break;
    }

    // Update user tracking data
    await this.updateTrackingData();
  }
  /*---------------------------------------- TRACKING EVENT END ----------------------------------------*/

  /*---------------------------------------- FILE COUNTER TRACKING START ----------------------------------------*/
  public async countEvent(eventName: string, eventAction: string, counter?: string, types?: string[]): Promise<Boolean>
  {
    // Check if user tracking is loaded
    await this.checkUserTracking();
    switch (eventAction)
    {
      // Counter Tracking
      case "Counter":
        switch (eventName)
        {
          case "File":
            this.userTracking.active.File = Number.parseInt(counter);
            types.forEach(type => 
            {
              switch (type)
              {
                case "application/pdf":
                  this.userTracking.active.Types.pdf++;
                  break;
                case "application/msword":
                  this.userTracking.active.Types.doc++;
                  break;
                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  this.userTracking.active.Types.doc++;
                  break;
                case "application/vnd.ms-excel":
                  this.userTracking.active.Types.xls++;
                  break;
                case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  this.userTracking.active.Types.xls++;
                  break;
                case "application/vnd.ms-powerpoint":
                  this.userTracking.active.Types.ppt++;
                  break;
                case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                  this.userTracking.active.Types.ppt++;
                  break;
                case "image/jpg":
                  this.userTracking.active.Types.jpg++;
                  break;
                case "image/jpeg":
                  this.userTracking.active.Types.jpg++;
                  break;
                case "image/png":
                  this.userTracking.active.Types.png++;
                  break;
                case "image/gif":
                  this.userTracking.active.Types.gif++;
                  break;
                case "text/plain":
                  this.userTracking.active.Types.txt++;
                  break;
                default:
                  this.userTracking.active.Types.other++;
                  break;
              }
            });
            //console.log(this.userTracking.active.Types);
            break;
          case "Shared":
            this.userTracking.active.Shared = Number.parseInt(counter);
            break;
          case "Favorite":
            this.userTracking.active.Favorite = Number.parseInt(counter);
            break;
          case "Trash":
            this.userTracking.active.Trash = Number.parseInt(counter);
            break;
          default:
        }
        break;

      default:
        console.error('Tracking could not be assigned!');
        break;
    }
    // Update user tracking data
    await this.updateTrackingData();
    return true;
  }
  /*---------------------------------------- FILE COUNTER TRACKING END ----------------------------------------*/

  /*---------------------------------------- FIRST TIME TRACKING LOAD START ----------------------------------------*/
  public async checkUserTracking()
  {
    if (!this.trackingLoaded)
    {
      this.userTracking =
      {
        filesUploaded: 0,
        filesDownloaded: 0,
        filesDeleted: 0,
        foldersUploaded: 0,
        foldersDownloaded: 0,
        foldersDeleted: 0,
        clicks: {
          File: 0,
          Shared: 0,
          Favorite: 0,
          Trash: 0
        },
        active: {
          File: 0,
          Shared: 0,
          Favorite: 0,
          Trash: 0,
          Types: {
            pdf: 0,
            doc: 0,
            xls: 0,
            ppt: 0,
            jpg: 0,
            png: 0,
            gif: 0,
            txt: 0,
            other: 0
          }
        }
      };

      // Check logged in user
      this.userID = await this.getUser();

      // Check if user tracking is already existing
      await this.loadTracking();

      // Set tracking loaded to true
      this.trackingLoaded = true;
    }
  }
  /*---------------------------------------- FIRST TIME TRACKING LOAD END ----------------------------------------*/

  /*---------------------------------------- GET BACKEND TRACKING DATA START ----------------------------------------*/
  private async loadTracking(): Promise<UserTracking>
  {
    // Get the tracking data from the backend
    //const url = `http://localhost/KeystoreTest/trackerAPI.php?userID=${encodeURIComponent(this.userService.userID)}`;

    // Update the tracking data in the backend
    const formData = new FormData();
    formData.append('user_id_ext', this.userID);
    formData.append('token', this.userToken);
    formData.append('tracking_data', '0');
    formData.append('mode', 'get');

    this.http.post(this.trackerApiURL, formData, { responseType: 'json' }).subscribe((response: UserTracking) =>
    {
      if (response)
      {
        // Set the tracking data
        this.userTracking.filesUploaded = response.filesUploaded;
        this.userTracking.filesDownloaded = response.filesDownloaded;
        this.userTracking.filesDeleted = response.filesDeleted;
        this.userTracking.foldersUploaded = response.foldersUploaded;
        this.userTracking.foldersDownloaded = response.foldersDownloaded;
        this.userTracking.foldersDeleted = response.foldersDeleted;
        this.userTracking.clicks.File = response.clicks.File;
        this.userTracking.clicks.Shared = response.clicks.Shared;
        this.userTracking.clicks.Favorite = response.clicks.Favorite;
        this.userTracking.clicks.Trash = response.clicks.Trash;
        this.userTracking.active.File = response.active.File;
        this.userTracking.active.Shared = response.active.Shared;
        this.userTracking.active.Favorite = response.active.Favorite;
        this.userTracking.active.Trash = response.active.Trash;
        this.userTracking.active.Types = response.active.Types;

        return this.userTracking;
      } else
      {
        // Return null if no tracking data was found
        console.error('No tracking found for the specified userID.');
        return null;
      }
    })
    return this.userTracking;
  }
  /*---------------------------------------- GET BACKEND TRACKING DATA END ----------------------------------------*/

  /*---------------------------------------- UPDATE BACKEND TRACKING DATA START ----------------------------------------*/
  private async updateTrackingData()
  {
    // Get the user information
    this.userToken = this.userService.userToken;

    // Update the tracking data in the backend
    const formData = new FormData();
    formData.append('user_id_ext', this.userID);
    formData.append('token', this.userToken);
    formData.append('tracking_data', JSON.stringify(this.userTracking));
    formData.append('mode', 'update');
    //console.log(this.userTracking);
    // Send the tracking data to the backend
    try
    {
      const response = await fetch(this.trackerApiURL, {
        method: 'POST',
        body: formData
      });

      // Check if the tracking data was updated successfully
      if (response.ok)
      {
        console.log('Tracking updated successfully.');
      } else
      {
        console.error('Failed to update tracking in keystore.');
      }
    } catch (error)
    {
      console.error('Error:', error);
    }
    //console.log(this.userTracking);
    // Delete the form data
    formData.delete;
  }
  /*---------------------------------------- UPDATE BACKEND TRACKING DATA END ----------------------------------------*/

  /*---------------------------------------- USER INFORMATION START ----------------------------------------*/
  private async getUser()
  {
    //Values for User ID and Token
    this.userID = await this.userService.getUserID();
    //console.log("<----- COMPONENT USER ID: ", this.userID, " ----->");
    // this.userToken = this.userService.getUserToken();
    this.userToken = await this.userService.getToken();

    //console.log("<----- COMPONENT USER TOKEN: ", this.userToken, " ----->");
    return this.userID;
  }
  /*---------------------------------------- USER INFORMATION END ----------------------------------------*/
}
