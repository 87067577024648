import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core'

import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';

import { FileElement } from '../../models/element';

import { NewFolderDialogComponent } from '../../dialogs/new-folder-dialog/new-folder-dialog.component';
import { RenameDialogComponent } from '../../dialogs/rename-dialog/rename-dialog.component';
import { FileExplorerRouteComponent } from './route/file-explorer-route.component';
import { UploadDialogComponent } from '../../dialogs/upload-dialog/upload-dialog.component';

import { FileService } from '../../services/file.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { SharedService } from 'src/app/services/shared.service';
import { FileChooserService } from '../../services/file-chooser.service';
import { ShareDialogComponent } from '../../dialogs/share-dialog/share-dialog.component';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
  selector: 'file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss'],
})
export class FileExplorerComponent implements OnChanges
{
  //Variables
  public math = Math;
  public localStorage = localStorage;
  public currentSortMode: string = localStorage.getItem('sortMode') || 'name';
  public currentSortDirection: string = localStorage.getItem('sorDirection') || 'ASC';
  breakpoint: number;

  constructor(
    public dialog: MatDialog,
    public fileService: FileService,
    public fileRoute: FileExplorerRouteComponent,
    public sharedService: SharedService,
    private userService: UserService,
    private fileChooserService: FileChooserService,
    private trackerService: TrackerService,
  )
  {

  }

  // Define Inputs
  @Input() fileElements: FileElement[]
  @Input() favFileElements: FileElement[]
  @Input() trashFileElements: FileElement[]
  @Input() canNavigateUp: boolean
  @Input() path: string
  @Input() searchText: string;
  @Input() viewMode: string;

  // Define Outputs
  @Output() folderAdded = new EventEmitter<{ name: string }>()
  //@Output() elementShare = new EventEmitter<FileElement>()
  @Output() elementRenamed = new EventEmitter<FileElement>()
  @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement }>()
  @Output() navigatedDown = new EventEmitter<FileElement>()
  @Output() navigatedUp = new EventEmitter()
  @Output() changeSort = new EventEmitter<{ selected: string; reverse: boolean }>();
  @Output() elementFavorite = new EventEmitter<FileElement>()
  @Output() trashElement = new EventEmitter<FileElement>()
  @Output() elementRecovered = new EventEmitter<FileElement>()
  @Output() emptyTrashElements = new EventEmitter<FileElement[]>()
  @Output() viewToggled = new EventEmitter<{ view: string }>()

  ngOnInit()
  {
    this.breakpoint = (window.innerWidth <= 400) ? 2 : (window.innerWidth <= 767 && window.innerWidth >= 400) ? 3 : 8;
  }

  onResize(event)
  {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : (event.target.innerWidth <= 767 && event.target.innerWidth >= 400) ? 3 : 8;
  }

  // Reacts when changes happen
  ngOnChanges(changes: SimpleChanges): void { }

  /*---------------------------------------- FILE/FOLDER HANDLING START ----------------------------------------*/

  // Set elementRecovered for Output
  removeElement(element: FileElement) 
  {
    this.trashElement.emit(element);
  }

  // Set elementRemoved for Output
  favoriteElement(element: FileElement) 
  {
    this.elementFavorite.emit(element);
  }

  // Set elementMoved for Output for moving elements down
  moveElement(element: FileElement, moveTo: FileElement) 
  {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  }

  // Set elementMoved for Output for moving elements up
  moveElementUp(element: FileElement, moveTo: string) 
  {
    this.elementMoved.emit({ element: element, moveTo: this.fileService.get(moveTo) });
  }

  setCurrentSort(currentSort: { sortMode: string, sortDirection: string })
  {
    this.currentSortMode = currentSort.sortMode;
    this.currentSortDirection = currentSort.sortDirection;
  }

  sortExplorer(sortConfig: { selected: string, reverse: boolean })
  {
    localStorage.setItem('sortMode', sortConfig.selected);
    localStorage.setItem('sortDirection', sortConfig.reverse.toString());

    this.changeSort.emit({ selected: sortConfig.selected, reverse: sortConfig.reverse });
  }

  // run when file in FileChooser is selected
  onFileDropped(event: any)
  {
    this.fileChooserService.fileDrop(event);
  }

  //File-Download with FileService
  download(element: FileElement) 
  {
    this.fileService.downloadFile(element.name, element.file_id, this.userService.userID, this.userService.userToken, element.shared, element.old_file_id);
  }

  //Folder-Download with FileService
  downloadFolder(element: FileElement)
  {
    this.fileService.downloadFolder(element.name, element.id, this.userService.userID, this.userService.userToken);
  }

  /*---------------------------------------- FILE/FOLDER HANDLING END ----------------------------------------*/


  /*---------------------------------------- NAVIGATION START ----------------------------------------*/

  // Set navigatedDown for Output
  navigate(element: FileElement) 
  {
    if (element.isFolder)
    {
      this.navigatedDown.emit(element);
    }
  }
  // Set navigatedDown for Output
  navigateToRoot() 
  {
    this.navigatedDown.emit(this.fileService.get('root'));
  }

  // Set navigatedUp for Output
  navigateUp() 
  {
    this.navigatedUp.emit();
  }

  /*---------------------------------------- NAVIGATION END ----------------------------------------*/


  /*---------------------------------------- DIALOG HANDLING START ----------------------------------------*/

  // Reacts when NewFolderDialogComponent closed --> set folderAdded for Output
  openNewFolderDialog() 
  {
    let dialogRef = this.dialog.open(NewFolderDialogComponent, {
      data: {
        parentId: this.sharedService.currentRoot ? this.sharedService.currentRoot.id : 'root'
      }
    });

    dialogRef.afterClosed().subscribe(res =>
    {
      if (res)
      {
        this.folderAdded.emit({ name: res });
      }
    });
  }

  // Reacts when UploadDialogComponent closed --> set folderAdded for Output
  openUploadDialog() 
  {
    let dialogRef = this.dialog.open(UploadDialogComponent, {
      data: {
        parentId: this.sharedService.currentRoot ? this.sharedService.currentRoot.id : 'root'
      }
    });
  }

  // Reacts when RenameDialogComponent closed --> set elementRenamed for Output
  openRenameDialog(element: FileElement) 
  {
    let dialogRef = this.dialog.open(RenameDialogComponent, {
      data: {
        element: element
      }
    });
    dialogRef.afterClosed().subscribe(res =>
    {
      if (res)
      {
        element.name = res;
        this.elementRenamed.emit(element);
      }
    });
  }

  // File-Share dialog
  openShareDialog(file_id: number, name: string, element: FileElement)
  {
    const dialogRef = this.dialog.open(ShareDialogComponent, {
      data: { file_id, name, element }
    });
  }

  /*---------------------------------------- DIALOG HANDLING END ----------------------------------------*/


  /*---------------------------------------- MENU HANDLING START ----------------------------------------*/

  // open menu by MouseEvent
  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) 
  {
    event.preventDefault();
    viewChild.openMenu();
  }

  /*---------------------------------------- MENU HANDLING END ----------------------------------------*/

  public async toggleView(view: string)
  {
    if (view === 'list')
    {
      localStorage.setItem("viewMode", "list");
      this.viewToggled.emit({ view: "list" });
    } else
    {
      localStorage.setItem("viewMode", "module");
      this.viewToggled.emit({ view: "module" });
    }
  }
}