import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CallbackComponent } from './authentication/callback/callback.component';
import { LoginComponent } from './authentication/login/login.component';
import { FileExplorerRouteComponent } from './file-explorer/component/file-explorer/route/file-explorer-route.component';

const routes: Routes = [
  { path: '', component: AppComponent },
  { path: 'login', component: LoginComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'file-explorer', component: FileExplorerRouteComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
