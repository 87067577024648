import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnInit, ViewChild, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './authentication/services/auth.service';
import { SharedService } from './services/shared.service';
import { MatSidenav } from '@angular/material/sidenav';
import { DarkmodeService } from './services/darkmode.service';

//Component declarations
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit
{
  title = 'PocHasomedCloudFrontend';

  public localStorage = localStorage;

  @HostBinding('class') componentCssClass;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(
    public router: Router,
    public authService: AuthService,
    public sharedService: SharedService,
    public overlay: OverlayContainer,
    public darkmodeService: DarkmodeService,
  ) { }

  async ngOnInit() 
  {
    console.log("<----- ngOnInit App ----->");
    // Check if development or production mode
    if (isDevMode())
    {
      console.log('Development!');
    } else
    {
      console.log('Production!');
    }

    console.log("<----- Check Theme ----->");
    await this.darkmodeService.initDarkMode(this.componentCssClass);

    console.log("<----- Check localStorage ----->");
    if (localStorage.getItem('access_token') == null && localStorage.getItem('callbackRunning') == 'false'
      || localStorage.getItem('access_token') == null && localStorage.getItem('callbackRunning') == null
      || localStorage.getItem('access_token') == "" && localStorage.getItem('callbackRunning') == null
      || localStorage.getItem('access_token') == "" && localStorage.getItem('callbackRunning') == 'false')
    {
      console.log("<----- localStorage empty ----->");
      this.sharedService.cleanLocalStorage();
      this.router.navigate(['login']);
      return;
    }
    console.log("<----- Check callbackRunning ----->");
    if (this.localStorage.getItem('callbackRunning') == 'true')
    {
      console.log("<----- Callback running ----->");
      if (!window.location.search.includes('code'))
      {
        this.sharedService.cleanLocalStorage();
        this.router.navigate(['login']);
      }
      return;
    }
    console.log("<----- Check access_token ----->");
    if (localStorage.getItem('access_token') != null && localStorage.getItem('access_token') != '' && localStorage.getItem('callbackRunning') == 'false')
    {
      console.log("<----- Check Validation ----->");
      if (await this.authService.checkAccessToken())
      {
        console.log("<----- Validation Successfull ----->");
        this.authService.isAuthorized = true;
        this.authService.isLoggedIn = true;

        this.router.navigate(['file-explorer']);
        return;
      } else
      {
        console.log("<----- Valid access_token ----->");
        this.sharedService.cleanLocalStorage();
        this.router.navigate(['login']);
        return;
      }
    }
  }

  async checkAccessToken()
  {
    try
    {
      const check_Access_Token = await this.authService.checkAccessToken();
      return check_Access_Token;
    } catch {
      return false;
    }
  }

  public async toggleTheme()
  {
    // Toggle theme
    if (this.componentCssClass === 'dark-theme')
    {
      // Set theme light
      this.componentCssClass = '';
      this.overlay.getContainerElement().classList.remove('dark-theme');
      document.body.style.backgroundColor = "var(---fafafa-greylight5)";
      document.body.style.color = "var(---000000-black)";
      localStorage.setItem("toggleTheme", "Light");
      this.sharedService.theme = "Light";
    } else
    {
      // Set theme dark
      this.componentCssClass = 'dark-theme';
      this.overlay.getContainerElement().classList.add('dark-theme');
      document.body.style.backgroundColor = "var(---3e3f3f-greydark)";
      document.body.style.color = "var(---ffffff-white)";
      localStorage.setItem("toggleTheme", "Dark");
      this.sharedService.theme = "Dark";
    }
  }

  public async logout()
  {
    // Logout
    this.authService.isAuthorized = false;
    this.authService.isLoggedIn = false;
    const access_token = (localStorage.getItem('access_token'));
    this.sharedService.cleanLocalStorage();
    const logoutRedirectURL = await this.authService.hasomedLogout(access_token);
    window.location.href = logoutRedirectURL;
  }
}