import { Directive, HostBinding, HostListener, OnInit } from '@angular/core';

import { FileExplorerRouteComponent } from '../component/file-explorer/route/file-explorer-route.component';

import { FileService } from '../services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/authentication/services/user.service';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective implements OnInit
{
  @HostBinding('class.fileover') fileOver: boolean;

  public fileId: string;
  public fileName: string;

  constructor(
    private fileService: FileService,
    private sharedService: SharedService,
    private fileRoute: FileExplorerRouteComponent,
    private userService: UserService
  ) { }

  ngOnInit(): void
  {
    this.fileService.fileId$.subscribe((fileId) =>
    {
      // Store the fileId value in a variable
      this.fileId = fileId;
    });
    this.fileService.fileName$.subscribe((fileName) =>
    {
      // Store the fileName value in a variable
      this.fileName = fileName;
    });
  }

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt) 
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) 
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public async ondrop(evt) 
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const selectedfiles = evt.dataTransfer.files;
    console.log("<----- File dropped ----->");

    //---
    Array.from(selectedfiles).forEach(async (element: File) =>
    {
      if (element.size <= 52428800)
      {
        await this.fileService.upload(element, this.userService.userID, this.userService.userToken);
        if (this.fileService.checkIfElementExists(element.name, this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root") === true)
        {
          var nameExists = true
          var int = 1
          do
          {
            const fileExtension = element.name.match(/\.\w{1,}(?!.)/)
            const fileName = element.name.replace(fileExtension?.toString(), '')
            this.fileName = fileName + '(' + int + ')' + fileExtension
            console.log(`A file with the name '${element.name}' already exists. File was renamed to '${this.fileName}'.`)
            nameExists = this.fileService.checkIfElementExists(this.fileName, this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root")
            int++
          } while (nameExists)
        } else
        {
          this.fileName = element.name
        }
        this.addFile({
          name: this.fileName,
          size: element.size,
          type: element.type,
          lastmodified: element.lastModified,
          uploaded: new Date().getTime()
        });
        this.fileRoute.updateFileElementQuery();
        console.log("<----- Filedrop successful ----->");
      } else
      {
        alert("Die maximale Dateigröße für den Upload beträgt 50MB. Reduzieren Sie die Größe Ihrer Datei oder wählen Sie eine andere Datei.");
      }
    });
    this.fileRoute.updateFileElementQuery();
    //---
  }

  // Add File to fileService as Element
  addFile(file: { name: string, size: number, type: string, lastmodified: number, uploaded: number }) 
  {
    // Use the stored fileId and fileName values
    this.fileService.add({
      isFolder: false,
      file_id: this.fileId,
      file_name: this.fileName,
      name: file.name,
      file_size: file.size,
      file_type: file.type,
      lastModified: file.lastmodified,
      uploaded: file.uploaded,
      parent: this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root",
    });
    this.fileRoute.updateFileElementQuery();
  }

}
