<!-- Toolbar -->
<mat-toolbar>
  <h2>Hochladen</h2>
  <span class="vertical-spacer"></span>
  <button mat-icon-button mat-dialog-close>
    <i class="fa-light fa-circle-xmark"></i>
  </button>
</mat-toolbar>

<!-- Input for Folder Name -->
<mat-dialog-content>
  <div class="dropzoneDialog">
    <!-- Drag and Drop Area -->
    <div *ngIf="!uploadProgress" class="dropzone" appDnd (fileDropped)="onFileDropped($event)">
      <!-- Input for FileChooser to select File to Upload -->
      <input type="file" id="file" class="file-input" accept={{requiredFileType}} (change)="onFileSelected($event)"
        #fileUpload multiple>
      <i class="fa-light fa-cloud-arrow-up fa-2xl uploadIcon"></i>
      <h3>Datei hierher ziehen</h3>
      <h3>oder</h3>
      <label *ngIf="!uploadProgress" (click)="fileUpload.click()"> Durchsuchen </label>
      <mat-error *ngIf="error == true">
        Upload fehlgeschlagen.
      </mat-error>
      <mat-error *ngIf="errorSize == true">
        Datei zu groß.
      </mat-error>
    </div>
    <!-- Upload Progress Div to Show Progress of Upload -->
    <div *ngIf="uploadProgress" class="upload">
      <!-- Cancel Upload Button/Icon -->
      <!--<button mat-fab color="primary" (click)="cancelUpload()">
        <mat-icon>delete_forever</mat-icon>
      </button>-->
      <!-- Progress Spinner to Show Progress of Upload -->
      <mat-spinner></mat-spinner>
      <h3>Hochladen..</h3>
    </div>
  </div>
</mat-dialog-content>