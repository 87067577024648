/**
 * Abstract class provides export function to export encryptedKey and joinArrayBuffer function.
 * It also defines the length of the initialisation vector (= iv).
 */
export abstract class WebCryptoKey {
    static readonly IV_LENGTH: number = 12;
    private readonly _key: CryptoKey;

    get cryptoKey(): CryptoKey {
        return this._key;
    }

    constructor(key: CryptoKey) {
        this._key = key;
    }

    /**
     * Abstract export method.
     * @param wrappingKey (optional) wrappingKey encrypts the cryptoKey (e.g. pek)
     */
    abstract export(wrappingKey?: WebCryptoKey): Promise<ArrayBuffer>;

    /**
     * Combines two ArrayBuffers by appending second to first.
     *
     * @param first first ArrayBuffer
     * @param second second ArrayBuffer
     * @returns new ArrayBuffer of combined first and second ArrayBuffer
     */
    protected joinArrayBuffer(first: ArrayBuffer, second: ArrayBuffer): ArrayBuffer {
        const data = new Uint8Array(first.byteLength + second.byteLength);
        data.set(new Uint8Array(first), 0);
        data.set(new Uint8Array(second), first.byteLength);

        return data.buffer;
    }
}
