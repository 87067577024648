import { Injectable } from '@angular/core';
import
{
    TenantAndUserKeys,
    ExportedUserKeys,
    ExportedKeyMaterial,
    WebCrypto,
    WebCryptoKey,
} from '../../../lib/crypto/crypto';
import { raw } from 'express';

@Injectable({
    providedIn: 'root',
})
export class CryptoService
{
    private webCrypto = new WebCrypto();

    constructor() { }

    /**
     * Set dmk.
     * @param dmk dmk
     */
    public setKey(dmk: string | ArrayBuffer)
    {
        this.webCrypto.setKey(dmk);
        console.log(dmk);
    }

    /**
     * Create key material (optionally with public key for shared secret).
     *
     * @param publicKeyForSharedSecret public key for shared secret
     * @returns key material as promise
     */
    public async generateTenantAndUserKeys(
        publicKeyForSharedSecret?: string | ArrayBuffer
    ): Promise<ExportedKeyMaterial>
    {
        //console.log(this.webCrypto.generateTenantAndUserKeys(publicKeyForSharedSecret));
        return this.webCrypto.generateTenantAndUserKeys(publicKeyForSharedSecret);
    }

    /**
     * Create user keys (encryptedPrivateKey, publicKey, pek).
     * @returns user key material as promise
     */
    public async generateUserKeys(): Promise<ExportedUserKeys>
    {
        return this.webCrypto.generateUserKeys();
    }

    /**
     * Imports keys and finally decrypts the DMK by computing the shared secret and decryption of KEK
     * @param rawMaterial all four client-keys (public, private, DMK, KEK)
     * @param pekKey private key encryption key of client - used to encrypt/decrypt the privatKey
     * @returns
     */

    public async importKeyMaterial(rawMaterial: TenantAndUserKeys, pekKey: string): Promise<void>
    {
        return this.webCrypto.importKeyMaterial(rawMaterial, pekKey);
    }

    /**
     * Removes the stored key. This requires a new import before any further encrypt/decrypt operations
     * can be performed.
     */

    public removeUsedKey(): void
    {
        return this.webCrypto.removeUsedKey();
    }

    /**
     * Encrypts a single string of data using the stored DMK or a provided key
     * @param data as string which should be encrypted
     * @param cryptoKey optional key used for decryption
     * @returns the encrypted text
     */
    public async encrypt(data: string | undefined, cryptoKey?: WebCryptoKey): Promise<string>
    {
        return this.webCrypto.encrypt(data, cryptoKey);
    }

    /**
     * Decrypts a single string of data using the stored DMK or a provided key
     * @param data string which should be decrypted
     * @param cryptoKey optional key used for decryption
     * @returns the decrypted data as ArrayBuffer
     */
    public async decrypt(data: string | undefined, cryptoKey?: WebCryptoKey): Promise<string>
    {
        return this.webCrypto.decrypt(data, cryptoKey);
    }
}
