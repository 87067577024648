import { AsymmetricWebCrypto } from '../asymmetric/asymmetricCrypto';
import {
    UserKeys,
    CryptologyKeyPair,
    ExportedKeyMaterial,
    ExportedUserKeys,
} from '../model/crypto.model';
import { SymmetricWebCrypto } from '../symmetric/symmetricCrypto';
import { WebCryptoKey } from '../webCryptoKey';

/**
 * WebCryptoKeyCreator provides functions to create the key material.
 */
export class WebCryptoKeyCreator {
    private symmetricCrypto = new SymmetricWebCrypto();
    private asymmetricCrypto = new AsymmetricWebCrypto();

    /**
     * Create key material (encryptedDmk, encryptedKek, encryptedPrivateKey, publicKey, pek).
     * @param publicKeyForSharedSecret public key to create shared secret with. If not set, created public key will be used
     * @returns key material as promise
     */
    public async createTenantAndUserKeys(
        publicKeyForSharedSecret?: WebCryptoKey
    ): Promise<ExportedKeyMaterial> {
        // 1. Create privateKey, publicKey and pek
        const { privateKey, publicKey, pek } = await this.createUserKeys();

        // 2. Create KEK
        const kek = await this.symmetricCrypto.generateKey();

        // 3. Create DMK
        const decryptedDmk = await this.symmetricCrypto.generateKey();

        // 4. Encrypt DMK by KEK
        const encryptedDmk = await decryptedDmk.export(kek);

        // 5. Create SharedSecret based on user-specific private key and public key of user
        const sharedSecret = await this.asymmetricCrypto.generateSharedSecret({
            privateKey: privateKey,
            publicKey: publicKeyForSharedSecret ?? publicKey,
        });

        // 6. Encrypt KEK by SharedSecret
        const encryptedKek = await kek.export(sharedSecret);

        return Promise.resolve({
            encryptedDmk: encryptedDmk,
            encryptedKek: encryptedKek,
            privateKey: await privateKey.export(pek),
            publicKey: await publicKey.export(),
            pek: await pek.export(),
        });
    }

    /**
     * Export user keys (encryptedPrivateKey, publicKey, pek).
     * @returns user key material as promise
     */
    public async exportUserKeys(): Promise<ExportedUserKeys> {
        const { privateKey, publicKey, pek } = await this.createUserKeys();

        return Promise.resolve({
            privateKey: await privateKey.export(pek),
            publicKey: await publicKey.export(),
            pek: await pek.export(),
        });
    }

    /**
     * Create user keys (privateKey, publicKey and pek).
     * @returns privateKey, publicKey and pek
     */
    private async createUserKeys(): Promise<UserKeys> {
        // Create asymmetric key material pair
        let keys: CryptologyKeyPair;
        try {
            keys = await this.asymmetricCrypto.generateKeys();
        } catch (e) {
            return Promise.reject('Error while generating asymmetric keys');
        }

        // Create symmetric key to encrypt and decrypt private asymmetric key
        const pek = await this.symmetricCrypto.generateKey(['wrapKey', 'unwrapKey']);

        return Promise.resolve({
            privateKey: keys.privateKey,
            publicKey: keys.publicKey,
            pek,
        });
    }
}
