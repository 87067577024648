import { WebStorageStateStore } from "oidc-client";

export const environment = {
    authority: 'https://hasomed-test.cidaas.eu/',
    client_id: '55f5913d-47ab-4d4c-88c7-e6486b627c39',
    clientSecret: "fbec8a07-bedc-4053-aa35-9204861aaecd",
    response_type: 'code',
    scope: 'openid profile email',
    automaticSilentRenew: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    extraQueryParams: {},
    usePKCE: true,
    production: false,

    /* SERVER IMPACT */
    redirect_uri: 'https://hasocloud.impact-tec.com:443/callback',
    post_logout_redirect_uri: 'https://hasocloud.impact-tec.com:443',
    silent_redirect_uri: 'https://hasocloud.impact-tec.com:443/silent-refresh.html',
};