import { Injectable } from '@angular/core';
import { FileService } from './file.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { FileExplorerRouteComponent } from '../component/file-explorer/route/file-explorer-route.component';

@Injectable({
  providedIn: 'root'
})
export class FileChooserService
{
  // Variables
  private b_fileId: string; //Backend(b_) file_id
  private b_fileName: string; //Backend(b_) file_name
  private fileName: string;
  private selectedFileName: string;

  constructor(
    private fileService: FileService,
    private sharedService: SharedService,
    private userService: UserService,
    private fileRoute: FileExplorerRouteComponent
  ) { }

  /*---------------------------------------- FILE-DROP FUNCTION START ----------------------------------------*/
  // run when file in FileChooser is selected
  async fileDrop(selectedfiles: FileList): Promise<boolean> 
  {

    //Get file_id and file_name from backend response
    this.fileService.fileId$.subscribe((fileId) =>
    {
      // Store the fileId value in a variable
      this.b_fileId = fileId;
    });
    this.fileService.fileName$.subscribe((fileName) =>
    {
      // Store the fileName value in a variable
      this.b_fileName = fileName;
    });

    if (selectedfiles.length < 1)
    {
      return false;
    }

    let success = true;

    for (const element of Array.from(selectedfiles))
    {
      if (element.size <= 1073741824)
      {
        await this.fileService.upload(
          element,
          this.userService.userID,
          this.userService.userToken
        );
        if (
          this.fileService.checkIfElementExists(
            element.name,
            this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root"
          )
        )
        {
          let nameExists = true;
          let int = 1;
          do
          {
            const fileExtension = element.name.match(/\.\w{1,}(?!.)/);
            const fileName = element.name.replace(fileExtension?.toString(), "");
            this.fileName = fileName + "(" + int + ")" + fileExtension;
            console.log(
              `A file with the name '${element.name}' already exists. File was renamed to '${this.fileName}'.`
            );
            nameExists = this.fileService.checkIfElementExists(
              this.fileName,
              this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root"
            );
            int++;
          } while (nameExists);
        } else
        {
          this.fileName = element.name;
        }

        this.addFile({
          name: this.fileName,
          size: element.size,
          type: element.type,
          lastmodified: element.lastModified,
          uploaded: new Date().getTime(),
        });
        console.log("<----- Filedrop successful ----->");
      } else
      {
        alert(
          "Die maximale Dateigröße für den Upload beträgt 1GB. Reduzieren Sie die Größe Ihrer Datei oder wählen Sie eine andere Datei."
        );
        success = false;
      }
    }

    return success;
  }
  /*---------------------------------------- FILE-DROP FUNCTION END ----------------------------------------*/


  /*---------------------------------------- FILE-SELECT FUNCTION START ----------------------------------------*/
  async fileSelect(event: any): Promise<boolean>
  {

    //Get file_id and file_name from backend response
    this.fileService.fileId$.subscribe((fileId) =>
    {
      // Store the fileId value in a variable
      this.b_fileId = fileId;
    });
    this.fileService.fileName$.subscribe((fileName) =>
    {
      // Store the fileName value in a variable
      this.b_fileName = fileName;
    });


    if (event.target.files.length < 1)
    {
      return false;
    }
    let success = true; // initialize the success flag
    for (let i = 0; i < event.target.files.length; i++)
    {
      var selectedfile = event.target.files[i];
      if (selectedfile)
      {
        console.log('File selected:', selectedfile.name);
        if (selectedfile.size <= 1073741824)
        {
          console.log(selectedfile.name)
          try
          {
            // Wait for the upload to complete
            await this.fileService.upload(
              selectedfile,
              this.userService.userID,
              this.userService.userToken
            );

            if (selectedfile instanceof File) {
              selectedfile.arrayBuffer().then(buffer => {
                // Clear the ArrayBuffer to free up memory
                buffer = null;
                console.log("Buffer cleared")
              });
            }

            if (
              this.fileService.checkIfElementExists(
                selectedfile.name,
                this.sharedService.currentRoot ? this.sharedService.currentRoot.id : 'root'
              ) === true
            )
            {
              let nameExists = true;
              let int = 1;
              do
              {
                const fileExtension = selectedfile.name.match(/\.\w{1,}(?!.)/);
                const fileName = selectedfile.name.replace(fileExtension?.toString(), '');
                this.fileName = fileName + '(' + int + ')' + fileExtension;
                console.log(
                  `A file with the name '${selectedfile.name}' already exists. File was renamed to '${this.fileName}'.`
                );
                nameExists = this.fileService.checkIfElementExists(
                  this.fileName,
                  this.sharedService.currentRoot ? this.sharedService.currentRoot.id : 'root'
                );
                int++;
              } while (nameExists);
            } else
            {
              this.fileName = selectedfile.name;
            }

            this.addFile({
              name: this.fileName,
              size: selectedfile.size,
              type: selectedfile.type,
              lastmodified: selectedfile.lastModified,
              uploaded: new Date().getTime(),
            });

            this.selectedFileName = this.fileName;
            console.log('Uploader Component: Upload complete');
          } catch (error)
          {
            console.error('Upload failed:', error);
            success = false; // update the success flag
          }
        } else
        {
          alert("Die maximale Dateigröße für den Upload beträgt 1GB. Reduzieren Sie die Größe Ihrer Datei oder wählen Sie eine andere Datei.");
          success = false; // update the success flag
        }
      }
    }
    return success; // return the final success flag
  }
  /*---------------------------------------- FILE-SELECT FUNCTION END ----------------------------------------*/


  /*---------------------------------------- FILE-STRUCTURE UPDATE START ----------------------------------------*/
  async addFile(file: { name: string, size: number, type: string, lastmodified: number, uploaded: number })
  {
    const parentFolderId = this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root";
    const parentFolder = this.fileService.map.get(parentFolderId);
    console.log("Parent folder ID: " + parentFolderId, parentFolder);

    // Check if the parent folder is shared
    if (parentFolder && parentFolder.sharedWith)
    {
      // Share the new file with the same users as the parent folder
      const sharedWith = parentFolder.sharedWith.slice(); // Create a copy of the array
      console.log("parent shared with", sharedWith);

      // Set parameters of the new file element
      const newFileElement = this.fileService.add({
        isFolder: false,
        file_id: this.b_fileId,
        file_name: this.b_fileName,
        name: file.name,
        file_size: file.size,
        file_type: file.type,
        lastModified: file.lastmodified,
        uploaded: file.uploaded,
        parent: parentFolderId,
        sharedWith: [],
      });

      // Update the query
      this.fileRoute.updateFileElementQuery();

      // Share the new file with each user in the copied array
      for (const targetUser of sharedWith)
      {
        await this.fileService.shareFile(newFileElement.file_id, newFileElement, targetUser);
      }

      // Update the sharedWith attribute of the new file element
      this.fileService.update(newFileElement.id, { sharedWith: sharedWith });
    }
    else
    {
      // Set parameters of the new file element
      const newFileElement = this.fileService.add({
        isFolder: false,
        file_id: this.b_fileId,
        file_name: this.b_fileName,
        name: file.name,
        file_size: file.size,
        file_type: file.type,
        lastModified: file.lastmodified,
        uploaded: file.uploaded,
        parent: this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root",
        sharedWith: [],
      });

      // Update the query
      this.fileRoute.updateFileElementQuery();
    }
  }
  /*---------------------------------------- FILE-STRUCTURE UPDATE END ----------------------------------------*/
}
