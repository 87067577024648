import { WebCryptoRng } from '../util/web-crypto-rng';
import { WebCryptoKey } from '../webCryptoKey';

import { SymmetricWebCryptoKey } from './symmetricCryptoKey';

/**
 * Generating of symmetric key for encryption and decryption
 */
export class SymmetricWebCrypto {
    /**
     * Generate exportable key for encryption and decryption of data.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/generateKey
     * @param mode defining the type of key to generate (AES 256)
     * @param keyUsage indicating what can be done with the newly generated key
     * @returns new generated key
     */
    public async generateKey(
        keyUsage: KeyUsage[] = ['encrypt', 'decrypt', 'wrapKey', 'unwrapKey']
    ): Promise<WebCryptoKey> {
        const algorithm: AesKeyGenParams = {
            name: `AES-GCM`,
            length: 256,
        };

        const key = await crypto.subtle.generateKey(algorithm, true, keyUsage);
        return new SymmetricWebCryptoKey(key);
    }

    /**
     * Encryption of data.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/encrypt
     * buffer containing the encoded data to be encrypted
     * @param key is used to encrypt the data
     * @param data is a decoded string
     * @returns the encrypted buffer with an iv
     */
    public async encrypt(key: WebCryptoKey, data: string | ArrayBuffer): Promise<ArrayBuffer> {
        const iv = WebCryptoRng.getRandomValues(WebCryptoKey.IV_LENGTH);
        const algorithm: AesGcmParams = {
            name: `AES-GCM`,
            iv: iv,
        };

        let buffer: ArrayBuffer;
        if (typeof data == 'string') {
            const encoder = new TextEncoder();
            buffer = encoder.encode(data);
        } else if (data instanceof ArrayBuffer) {
            buffer = data;
        } else {
            return Promise.reject('Data is not even a string or an ArrayBuffer');
        }

        const encryptedBuffer = await crypto.subtle.encrypt(algorithm, key.cryptoKey, buffer);

        const encryptedBufferWithIv = new Uint8Array(iv.byteLength + encryptedBuffer.byteLength);
        encryptedBufferWithIv.set(iv, 0);
        encryptedBufferWithIv.set(new Uint8Array(encryptedBuffer), iv.byteLength);

        return Promise.resolve(encryptedBufferWithIv);
    }

    /**
     * Decrypts some encrypted data.
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/decrypt
     * @param key to decrypt with
     * @param encryptedBufferWithIv data to decrypt
     * @returns decrypted data
     */
    public async decrypt(
        key: WebCryptoKey,
        encryptedBufferWithIv: ArrayBuffer
    ): Promise<ArrayBuffer> {
        const iv = encryptedBufferWithIv.slice(0, WebCryptoKey.IV_LENGTH);
        const data = encryptedBufferWithIv.slice(WebCryptoKey.IV_LENGTH);
        const algorithm: AesGcmParams = {
            name: `AES-GCM`,
            iv: iv,
        };

        return crypto.subtle.decrypt(algorithm, key.cryptoKey, data);
    }

    /**
     * Import a key and optionally unwraps it.
     *
     * Unwrap the key if its encrypted ("wrapped") with the unwrapping key.
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/unwrapKey
     *
     * @param key key or encrypted (=wrapped) key
     * @param unwrappingKey (optional) key which is used to unwrap another key
     * @param keyUsage (optional) array indicating what can be done with the key
     * @returns imported (unwraped) key
     */
    public async import(
        key: ArrayBuffer,
        keyUsage: KeyUsage[],
        unwrappingKey?: WebCryptoKey
    ): Promise<WebCryptoKey> {
        const unwrappedKeyAlgorithmen: string = 'AES-GCM';
        const iv = key.slice(0, WebCryptoKey.IV_LENGTH);
        const encryptedKey = key.slice(WebCryptoKey.IV_LENGTH);
        const unwrapAlgorithm: AesGcmParams = {
            name: `AES-GCM`,
            iv: iv,
        };
        const unwrap = unwrappingKey !== undefined;

        const importedKey = unwrap
            ? await crypto.subtle.unwrapKey(
                  'raw',
                  encryptedKey,
                  unwrappingKey.cryptoKey,
                  unwrapAlgorithm,
                  unwrappedKeyAlgorithmen,
                  true,
                  keyUsage
              )
            : await crypto.subtle.importKey('raw', key, unwrappedKeyAlgorithmen, true, keyUsage);

        return Promise.resolve(new SymmetricWebCryptoKey(importedKey));
    }
}
