import { ExportedKeyMaterial, ExportedUserKeys, TenantAndUserKeys } from './model/crypto.model';
import { ArrayBufferUtil } from './util/array-buffer-util';
import { WebCryptoKey } from './webCryptoKey';
import { SymmetricWebCrypto } from './symmetric/symmetricCrypto';
import { WebCryptoKeyCreator } from './creator/webCryptoKeyCreator';
import { WebCryptoKeyImporter } from './importer/webCryptoKeyImporter';

/**
 * WebCrypto Service provides functionality to create, import and delete key material and decrypt/encrypt data.
 */
export class WebCrypto {
    private readonly symmetricCrypto = new SymmetricWebCrypto();
    private readonly decoder = new TextDecoder();
    private readonly importer = new WebCryptoKeyImporter();
    private readonly creator = new WebCryptoKeyCreator();
    private dmk?: Promise<WebCryptoKey>;

    /**
     * Set dmk.
     * @param dmk dmk
     */
    public setKey(dmk: string | ArrayBuffer) {
        const buffer = typeof dmk === 'string' ? ArrayBufferUtil.toArrayBuffer(dmk) : dmk;
        this.dmk = this.symmetricCrypto.import(buffer, ['encrypt', 'decrypt']);
    }

    /**
     * Get dmk.
     * @returns dmk promise
     */
    private getKey(): Promise<WebCryptoKey> {
        return this.dmk ? this.dmk : Promise.reject('Crypto: No key available');
    }

    /**
     * Create key material (optionally with public key for shared secret).
     *
     * @param publicKeyForSharedSecret public key for shared secret
     * @returns key material as promise
     */
    public async generateTenantAndUserKeys(
        publicKeyForSharedSecret?: string | ArrayBuffer
    ): Promise<ExportedKeyMaterial> {
        let key: WebCryptoKey | undefined = undefined;

        if (publicKeyForSharedSecret) {
            const buffer =
                typeof publicKeyForSharedSecret === 'string'
                    ? ArrayBufferUtil.toArrayBuffer(publicKeyForSharedSecret)
                    : publicKeyForSharedSecret;
            key = await this.symmetricCrypto.import(buffer, ['wrapKey', 'unwrapKey']);
        }

        return this.creator.createTenantAndUserKeys(key);
    }

    /**
     * Create user keys (encryptedPrivateKey, publicKey, pek).
     * @returns user key material as promise
     */
    public async generateUserKeys(): Promise<ExportedUserKeys> {
        return this.creator.exportUserKeys();
    }

    /**
     * Imports keys and finally decrypts the DMK by computing the shared secret and decryption of KEK
     * @param rawMaterial all four client-keys (public, private, DMK, KEK)
     * @param pekKey private symmetric key of client - used to encrypt/decrypt the privatKey
     * @returns
     */
    public async importKeyMaterial(rawMaterial: TenantAndUserKeys, pekKey: string): Promise<void> {
        // Store imported key
        this.dmk = this.importer.importKeyMaterial(rawMaterial, pekKey);
    }

    /**
     * Removes the stored key. This requires a new import before any further encrypt/decrypt operations
     * can be performed.
     */
    public removeUsedKey(): void {
        this.dmk = undefined;
    }

    /**
     * Encrypts a single string of data using the stored DMK or a provided key
     * @param data as string which should be encrypted
     * @param cryptoKey optional key used for decryption
     * @returns the encrypted text
     */
    public async encrypt(data: string | undefined, cryptoKey?: WebCryptoKey): Promise<string> {
        if (!data) return '';
        const key = cryptoKey ?? (await this.getKey());
        const encrypted = await this.symmetricCrypto.encrypt(key, data);
        return ArrayBufferUtil.toBase64(encrypted);
    }

    /**
     * Decrypts a single string of data using the stored DMK or a provided key
     * @param data string which should be decrypted
     * @param cryptoKey optional key used for decryption
     * @returns the decrypted data as ArrayBuffer
     */
    public async decrypt(data: string | undefined, cryptoKey?: WebCryptoKey): Promise<string> {
        if (!data) return '';

        const key = cryptoKey ?? (await this.getKey());
        const encryptedBuffer = ArrayBufferUtil.toArrayBuffer(data);
        const decrypted = await this.symmetricCrypto.decrypt(key, encryptedBuffer);
        return this.decoder.decode(decrypted);
    }
}
