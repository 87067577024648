import { Component, Input, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FileService } from '../../services/file.service';


@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent
{
  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent>,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  //Variables
  @Input() isFolder = this.data.element.isFolder as Boolean;
  @Input() fileExtension = this.data.element.name.match(/\.\w{1,}(?!.)/);
  @Input() fileName = this.data.element.name.replace(this.fileExtension?.toString(), '')
  @Input() folderName = this.data.element.name as string;
  folderExists: boolean = false;

  // check if a folder with the name already exists
  folderNameChanged(newFolderName: string)
  {
    if (this.isFolder === true)
    {
      this.folderExists = this.fileService.checkIfElementExists(newFolderName, this.data.element.parent)
    } else
    {
      this.folderExists = this.fileService.checkIfElementExists(newFolderName + this.fileExtension, this.data.element.parent)
    }
  }

  // OnInit Function
  ngOnInit() { }
}
