import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileElement } from '../../models/element';
import { FileService } from '../../services/file.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-shared',
  templateUrl: './shared.component.html',
  styleUrls: ['./shared.component.scss']
})
export class SharedComponent implements OnInit
{
  public math = Math;
  public currentSortMode: string = localStorage.getItem('sortMode') || 'name';
  public currentSortDirection: string = localStorage.getItem('sorDirection') || 'ASC';
  breakpoint: number;

  constructor(
    private fileService: FileService
  ) { }

  @Input() fileElements: FileElement[];
  @Input() path: string;
  @Input() canNavigateUp: boolean;
  @Input() searchText: string;
  @Input() viewMode: string;

  @Output() navigatedDown = new EventEmitter<FileElement>()
  @Output() navigatedUp = new EventEmitter();
  @Output() trashElement = new EventEmitter<FileElement>()
  @Output() viewToggled = new EventEmitter<{ view: string }>()

  async ngOnInit()
  {
    this.breakpoint = (window.innerWidth <= 400) ? 2 : (window.innerWidth <= 767 && window.innerWidth >= 400) ? 3 : 8;
  }

  updateFileElementsRootLevel() {
    this.fileElements = Array.from(this.fileService.resultMap.values()).filter(element => element.parent === 'root');
  }

  onResize(event)
  {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : (event.target.innerWidth <= 767 && event.target.innerWidth >= 400) ? 3 : 8;
  }

  /*---------------------------------------- FILE/FOLDER HANDLING START ----------------------------------------*/

  setCurrentSort(currentSort: { sortMode: string, sortDirection: string })
  {
    this.currentSortMode = currentSort.sortMode;
    this.currentSortDirection = currentSort.sortDirection;
  }

  // Set elementRecovered for Output
  removeElement(element: FileElement) 
  {
    // this.trashElement.emit(element);
    this.fileService.deleteShared(element.id);
  }

  //File-Download with FileService using the shared credentials
  download(element: FileElement) 
  {
    this.fileService.downloadSharedFile(element.name, element.file_id, element.old_file_id);
  }
  
  downloadFolder(element: FileElement) 
  {
    this.fileService.downloadSharedFolder(element.name, element.id);
  }
  /*---------------------------------------- FILE/FOLDER HANDLING END ----------------------------------------*/


  /*---------------------------------------- NAVIGATION START ----------------------------------------*/

  // Set navigatedDown for Output
  navigate(element: FileElement) 
  {
    if (element.isFolder)
    {
      this.navigatedDown.emit(element);
    }
  }

  // Set navigatedDown for Output
  navigateToRoot() 
  {
    this.navigatedDown.emit(this.fileService.get('root'));
  }

  // Set navigatedUp for Output
  navigateUp() 
  {
    this.navigatedUp.emit();
  }

  /*---------------------------------------- NAVIGATION END ----------------------------------------*/


  /*---------------------------------------- MENU HANDLING START ----------------------------------------*/

  // open menu by MouseEvent
  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) 
  {
    event.preventDefault();
    viewChild.openMenu();
  }

  /*---------------------------------------- MENU HANDLING END ----------------------------------------*/

  public async toggleView(view: string)
  {
    if (view === 'list')
    {
      localStorage.setItem("viewMode", "list");
      this.viewToggled.emit({ view: "list" });
    } else
    {
      localStorage.setItem("viewMode", "module");
      this.viewToggled.emit({ view: "module" });
    }
  }

  /* Not in use
  toggleSidenav()
  {
    this.sidenav.toggle();
  } 
  */
}
