import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FileService } from '../../services/file.service'
import { FileElement } from '../../models/element';
import { file } from 'jszip';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.scss']
})
export class ShareDialogComponent
{
  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    public fileService: FileService,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: { element: FileElement, file_id: string, name: string }
  ) { }

  //Variables
  targetName: string;

  // JSON-String for file data to send to the Share API in the FileService with targetName
  async shareFile(targetName: string)
  {
    //Check if E-Mail exists
    const mailFormData = new FormData()
    mailFormData.append('mode', 'checkifmailexists');
    mailFormData.append('user_email', targetName);

    const userMailResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", mailFormData).toPromise();
    const userMailResponseNumber = Number(userMailResponse);

    if (userMailResponseNumber === 0)
    {
      alert("Dieser User nutzt die Hasocloud leider nicht. Bitte versuche es mit einer anderen E-Mail-Adresse.")
    }
    else if (userMailResponseNumber === 1)
    {
      if (this.data.element.isFolder === false)
      {
        console.log("<<<<<----- Target file and name ----->>>>>", this.data.element, this.data.file_id, this.data.name, " ", targetName);
        //const jsonData = JSON.stringify(this.data.element);
        await this.fileService.shareFile(this.data.file_id, this.data.element, targetName);
        this.dialogRef.close();
      } else
      {
        console.log("<<<<<----- Target folder and name ----->>>>>", this.data.element,);
        await this.shareFolder(this.data.element.file_id, targetName);
        this.dialogRef.close();
      }
    }
  }
  
  async shareFolder(folderId: string, targetName: string) {
    const files = [];
    this.fileService.shareFile(folderId, this.data.element, targetName);
    for (const [file_id, file] of this.fileService.map.entries()) {
      if (file.parent === this.data.element.id) {
        files.push(file);
      }
      // console.log("Files in Folder", files)
    }
    for (const fileElement of files) {
      await this.fileService.shareFile(fileElement.file_id, fileElement, targetName);
      console.log("ShareFolder", this.data.element, fileElement);
    }
  }

  // OnInit Function
  ngOnInit() { }
}