import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';

import { FileElement } from '../../../models/element';

import { AuthService } from 'src/app/authentication/services/auth.service';
import { FileService } from '../../../services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
  selector: 'app-route',
  templateUrl: './file-explorer-route.component.html',
  styleUrls: ['./file-explorer-route.component.scss']
})
export class FileExplorerRouteComponent
{
  //File Elements for Explorer
  public fileElements: Observable<FileElement[]>;

  //Shared File Elements for Explorer
  public sharedFileElements: Observable<FileElement[]>;

  //Favorite File Elements for Explorer
  public favFileElements: Observable<FileElement[]>;

  //Trash File Elements for Explorer
  public trashFileElements: Observable<FileElement[]>;

  //Variables
  public userID: string;
  public userToken: string;
  public currentPath: string;
  public canNavigateUp = false;
  public viewMode: string = localStorage.getItem("viewMode") || "module";
  private currentRoot: FileElement;

  constructor(
    private fileService: FileService,
    public sharedService: SharedService,
    public authService: AuthService,
    public userService: UserService,
    private trackerService: TrackerService
  ) { }

  //OnInit to generate Dummy-Data
  async ngOnInit()
  {
    console.log("<----- ngOnInit FileExplorerRoute ----->");

    try
    {
      this.userID = await this.userService.getUserID();
      await this.authService.checkUser();
      await this.userService.getToken();
      await this.userService.getPrimaryKey();
      this.userToken = this.userService.userToken;
  
      try {
        const decryptedResponse: string = await this.fileService.getFileStructure(this.userID, this.userToken).toPromise();
        const mapData = JSON.parse(decryptedResponse);
        const map = new Map<string, FileElement>(mapData);
        this.fileService.map = map;
        this.updateFileElementQuery();
      } catch (e) {
        // console.error('Error getting file structure:', e);
        console.log("No File Structure found");
      }
  
      this.fileService.getSharedStructure().then((resultMapString) => {
        const mapData = JSON.parse(resultMapString);
        const map = new Map<string, FileElement>(mapData);
        this.fileService.resultMap = map;
      });
      this.updateFileElementQuery()
      this.sharedService.setCurrentRoot(this.currentRoot);
    } catch
    {

    }

  }
  /*---------------------------------------- FILE/FOLDER HANDLING START ----------------------------------------*/

  // Add File to fileService as Element
  addFile(file: { name: string })
  {
    this.fileService.add({ isFolder: false, name: file.name, parent: this.currentRoot ? this.currentRoot.id : 'root' });
    this.updateFileElementQuery();
  }

  // Add Folder to fileService as Element
  addFolder(folder: { name: string })
  {
    const identifier = Math.floor(1000 + Math.random() * 9000).toString();
    this.fileService.add({ isFolder: true, name: folder.name, file_id: identifier, parent: this.currentRoot ? this.currentRoot.id : 'root' , sharedWith: []});
    this.updateFileElementQuery();
  }

  // Move File or Folder into another Folder
  // moveElement(event: { element: FileElement; moveTo: FileElement })
  // {
  //   console.log(this.fileService.get(event.moveTo.id).parent)
    // if (event.moveTo.id === event.element.parent)
    // {
    //   this.fileService.update(event.element.id!, { parent: this.fileService.get(event.moveTo.id).parent });
    // } else
    // {
    //   this.fileService.update(event.element.id!, { parent: event.moveTo.id });
    // }
  //   this.updateFileElementQuery();
  //   console.log("Element bewegt");
  // }

  async moveElement(event: { element: FileElement; moveTo: FileElement }) {
    const sourceElement = event.element;
    const destinationFolder = event.moveTo;
    
    const sourceParentFolder = this.fileService.get(sourceElement.id);
  
    // Check if the destination folder is the root folder
    if (destinationFolder.id === sourceElement.parent) {
      // Move the element to the root folder
      this.fileService.update(sourceElement.id!, { parent: this.fileService.get(destinationFolder.id).parent });
    } else {
      // Perform the actual move
      this.fileService.update(sourceElement.id!, { parent: destinationFolder.id });
    }

  
    this.updateFileElementQuery();
    
    if (sourceParentFolder && sourceParentFolder.sharedWith) {
      // If the source parent folder was shared, remove the moved element from sharedWith
      const sharedWith = sourceParentFolder.sharedWith.slice(); // Create a copy of the array
      const movedElementSharedWith = sourceElement.sharedWith || [];
  
      // Remove the moved element from the sharedWith array
      for (const targetUser of movedElementSharedWith) {
        const index = sharedWith.indexOf(targetUser);
        if (index !== -1) {
          sharedWith.splice(index, 1);
        }
      }
  
      // Update the sharedWith attribute of the source parent folder
      this.fileService.update(sourceParentFolder.id!, { sharedWith: sharedWith });
    }
  
    if (destinationFolder && destinationFolder.sharedWith) {
      // Share the moved element with the same users as the destination folder
      const sharedWith = destinationFolder.sharedWith.slice(); // Create a copy of the array
      const movedElementSharedWith = sourceElement.sharedWith || [];
  
      // Share the moved element with each user in the copied array
      for (const targetUser of sharedWith) {
        if (!movedElementSharedWith.includes(targetUser)) {
          await this.fileService.shareFile(sourceElement.file_id, sourceElement, targetUser);
          movedElementSharedWith.push(targetUser);
        }
      }
  
      // Update the sharedWith attribute of the moved element
      this.fileService.update(sourceElement.id!, { sharedWith: movedElementSharedWith });
    }
  
    console.log("Element moved");
  }

  // Rename File or Folder
  renameElement(element: FileElement)
  {
    this.fileService.update(element.id!, { name: element.name });
    this.updateFileElementQuery();
    console.log("Element umbenannt");
  }

  /*---------------------------------------- FILE/FOLDER HANDLING END ----------------------------------------*/

  /*---------------------------------------- FAVORITES HANDLING START ----------------------------------------*/

  // Favorit File or Folder from fileService by ElementID
  favoriteElement(element: FileElement)
  {
    if (element.favorite === true)
    {
      this.fileService.update(element.id!, { favorite: false });
    } else
    {
      this.fileService.update(element.id!, { favorite: true });
      if (element.isFolder)
      {
        this.fileService.map.forEach((value) =>
        {
          if (value.parent === element.id)
          {
            this.favoriteElement(value);
          }
        });
      }
    }
    this.updateFileElementQuery();
    //console.log("Element favorisiert");
  }

  // Favorit File or Folder from fileService by ElementID
  removeFavoriteElement(element: FileElement)
  {
    this.fileService.update(element.id!, { favorite: false });
    if (element.isFolder)
    {
      this.fileService.map.forEach((value) =>
      {
        if (value.parent === element.id)
        {
          this.removeFavoriteElement(value);
        }
      });
    }
    this.updateFileElementQuery();
    //console.log("Element aus Favoriten entfernt");
  }

  /*---------------------------------------- FAVORITES HANDLING START ----------------------------------------*/

  /*---------------------------------------- TRASH HANDLING START ----------------------------------------*/

  // Remove File or Folder from fileService by ElementID
  trashElement(element: FileElement)
  {
    this.fileService.update(element.id!, { trash: true, parent: 'root' });
    if (element.isFolder)
    {
      this.fileService.map.forEach((value) =>
      {
        if (value.parent === element.id)
        {
          this.trashElement(value);
        }
      });
    }
    this.updateFileElementQuery();
    console.log("Element entfernt");
  }

  // Remove File or Folder from fileService by ElementID
  recoverElement(element: FileElement)
  {
    this.fileService.update(element.id!, { trash: false });
    this.fileService.restoreFile(element.file_id);
    if (element.isFolder)
    {
      this.fileService.map.forEach((value) =>
      {
        if (value.parent === element.id)
        {
          this.recoverElement(value);
        }
      });
    }
    this.updateFileElementQuery();
    console.log("Element wiederhergestellt");
  }

  // Remove File or Folder from fileService by ElementID
  removePermanentElement(element: FileElement)
  {
    this.fileService.delete(element.id!);
    this.fileService.deleteFromBackend(element.file_id);
    this.updateFileElementQuery();
    if (element.isFolder)
    {
      this.trackerService.logEvent("Folder", "Deleted");
    } else
    {
      this.trackerService.logEvent("File", "Deleted");
    }
    console.log("Element entfernt");
  }

  // Remove File or Folder from fileService by ElementID
  emptyTrash(elements: FileElement[])
  {
    for (let i = 0; i < elements.length; i++)
    {
      this.fileService.delete(elements[i].id!);
      this.fileService.deleteFromBackend(elements[i].file_id);
      if (elements[i].isFolder)
      {
        this.fileService.map.forEach((value) =>
        {
          if (value.parent === elements[i].id)
          {
            this.removePermanentElement(value);
          }
        });
      }
      this.trackerService.logEvent("File", "Deleted");
    }
    this.updateFileElementQuery();
    console.log("Papierkorb geleert");
  }

  /*---------------------------------------- TRASH HANDLING END ----------------------------------------*/

  /*---------------------------------------- NAVIGATION START ----------------------------------------*/

  // Navigate into Folder
  navigateToFolder(element: FileElement)
  {
    this.currentRoot = element;
    // console.log(this.currentRoot)
    this.updateFileElementQuery();
    if (this.currentRoot)
    {
      this.currentPath = this.pushToPath(this.currentPath, element.name);
      this.canNavigateUp = true;
    } else
    {
      this.currentPath = '';
      this.canNavigateUp = false;
    }
    this.sharedService.setCurrentRoot(this.currentRoot);
  }

  // Navigate out of a Folder
  navigateUp()
  {
    if (this.currentRoot && this.currentRoot.parent === 'root')
    {
      this.currentRoot = null!;
      this.canNavigateUp = false;
      this.updateFileElementQuery();
    } else
    {
      this.currentRoot = this.fileService.get(this.currentRoot.parent)!;
      this.updateFileElementQuery();
    }
    this.currentPath = this.popFromPath(this.currentPath);
    this.sharedService.setCurrentRoot(this.currentRoot);
  }

  // Function to move into antoher Path
  pushToPath(path: string, folderName: string)
  {
    let p = path ? path : '';
    p += `${folderName} / `;
    return p;
  }

  // Function to get the explorer/location path for header text
  popFromPath(path: string)
  {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  /*---------------------------------------- NAVIGATION END ----------------------------------------*/

  toggleView(view: { view: string })
  {
    this.viewMode = view.view;
    this.updateFileElementQuery();
  }

  // Update fileElements for Explorer View. It's necessary to see the renamed, deleted or added folder or file
  async updateFileElementQuery()
  {
    // console.log("<----- UpdateQuery called ----->");
    this.fileElements = this.fileService.queryInFolder(this.currentRoot ? this.currentRoot.id : 'root');
    this.sharedFileElements = this.fileService.queryInFolderShared(this.currentRoot ? this.currentRoot.id : 'root');
    this.favFileElements = this.fileService.queryInFolderFavorites(this.currentRoot ? this.currentRoot.id : 'root');
    this.trashFileElements = this.fileService.queryInFolderTrash(this.currentRoot ? this.currentRoot.id : 'root');
    this.userID = this.userService.userID;
    this.userToken = this.userService.userToken;

    await this.fileService.updateFileStructure(this.userID, this.userToken);
    await new Promise(resolve => setTimeout(resolve, 1000));
    await this.fileService.getFileStructure(this.userID, this.userToken);

    await this.onFileCount("File", this.fileElements);
    await this.onFileCount("Shared", this.sharedFileElements);
    await this.onFileCount("Favorite", this.favFileElements);
    await this.onFileCount("Trash", this.trashFileElements);
  }

  /*---------------------------------------- TRACKING START ----------------------------------------*/

  async onFileCount(type: string, fileElements: Observable<FileElement[]> | FileElement[])
  {
    let fileCount: string;
    let fileTypes: string[] = [];
    if (fileElements instanceof Observable)
    {
      fileElements.subscribe(result =>
      {
        fileCount = result.length.toString()
        result.forEach(element =>
        {
          if (element.file_type != undefined || element.file_type != null)
          {
            fileTypes.push(element.file_type)
          }
        });
      });
    } else
    {
      if (fileElements != undefined)
      {
        fileCount = fileElements.length.toString();
        fileElements.forEach(element =>
        {
          if (element.file_type != undefined || element.file_type != null)
          {
            fileTypes.push(element.file_type)
          }
        });
      } else
      {
        fileCount = "0";
      }
    }

    await this.trackerService.countEvent(type, "Counter", fileCount, fileTypes);
  }

  onTabClick(event)
  {
    this.navigateToFolder(this.fileService.get('root'));
    switch (event.index)
    {
      case 0:
        this.onFilesClicked();
        break;
      case 1:
        this.onSharedClicked();
        break;
      case 2:
        this.onFavoriteClicked();
        break;
      case 3:
        this.onTrashClicked();
        break;
    }
  }

  onFilesClicked()
  {
    //this.trackerService.logClick('File');
    this.trackerService.logEvent('File', 'Clicks');
  }

  onSharedClicked()
  {
    //this.trackerService.logClick('Shared');
    this.trackerService.logEvent('Shared', 'Clicks');
  }

  onFavoriteClicked()
  {
    //this.trackerService.logClick('Favorite');
    this.trackerService.logEvent('Favorite', 'Clicks');
  }

  onTrashClicked()
  {
    //this.trackerService.logClick('Trash');
    this.trackerService.logEvent('Trash', 'Clicks');
  }

  /*---------------------------------------- TRACKING END ----------------------------------------*/

}
