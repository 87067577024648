import { CryptologyKeyPair } from '../model/crypto.model';
import { SymmetricWebCryptoKey } from '../symmetric/symmetricCryptoKey';
import { WebCryptoKey } from '../webCryptoKey';
import { AsymmetricWebCryptoKey } from './asymmetricCryptoKey';

/**
 * Creating asymmetric keypair for calculating the Shared Secret
 * Import and decryption of encrypted key with typ ECDH
 */
export class AsymmetricWebCrypto {
    /**
     * Use elliptic curve to create an asymmetric key pair.
     *
     * Usage of Elliptic Curve Diffie-Hellman (ECDH) with 521 bit encryption-strength.
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/generateKey
     * @returns privatKey and publicKey
     */
    public async generateKeys(): Promise<CryptologyKeyPair> {
        const algorithm: EcKeyGenParams = {
            name: 'ECDH',
            namedCurve: 'P-521',
        };

        const keys = await crypto.subtle.generateKey(algorithm, true, ['deriveKey']);

        return Promise.resolve({
            privateKey: new AsymmetricWebCryptoKey(keys.privateKey),
            publicKey: new AsymmetricWebCryptoKey(keys.publicKey),
        });
    }

    /**
     * Import a key and optionally unwraps it.
     *
     * Unwrap the key if its encrypted ("wrapped") with the unwrapping key.
     * UnwrapKey decrypts the key and then imports it, returning a CryptoKey object.
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/unwrapKey
     *
     * 'iv' is an initialization vector. It must be unique for each message encrypted with the same key. (AES-GCM nonce)
     * AES-GCM is used to decrypt the exported key
     * ECDH is the type of key to unwrap
     *
     * @param key or encrypted (=wrapped) key with iv (e.g. privateKey) to be imported
     * @param unwrappingKey (optional) key which is used to unwrap another key
     * @returns imported (unwrapped) key
     */
    public async import(key: ArrayBuffer, unwrappingKey?: CryptoKey): Promise<WebCryptoKey> {
        const unwrappedKeyAlgorithmen: EcKeyGenParams = {
            name: 'ECDH',
            namedCurve: 'P-521',
        };
        let importedKey: CryptoKey;
        if (unwrappingKey) {
            // key is encrypted and with IV here
            const iv = key.slice(0, WebCryptoKey.IV_LENGTH); // [IV | EncryptedKey]
            const encryptedKey = key.slice(WebCryptoKey.IV_LENGTH);
            const unwrapAlgorithm: AesGcmParams = {
                name: `AES-GCM`,
                iv: iv,
            };

            importedKey = await crypto.subtle.unwrapKey(
                'jwk',
                encryptedKey,
                unwrappingKey,
                unwrapAlgorithm,
                unwrappedKeyAlgorithmen,
                false,
                ['deriveKey']
            );
        } else {
            importedKey = await crypto.subtle.importKey(
                'raw',
                key,
                unwrappedKeyAlgorithmen,
                true,
                []
            );
        }

        return new AsymmetricWebCryptoKey(importedKey);
    }

    /**
     * Derive a Shared Secret from an asymmetric keyPair.
     *
     * The derived, exportable Shared Secret is used for encryption and decryption of other keys.
     * ECDH is the derivation algorithm
     * Derived key has the type of a AES-GCM algorithm
     * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/deriveKey
     * @param keyPair consists of client-specific privatKey and publicKey of the owner
     * @returns symmetric Shared Secret
     */
    public async generateSharedSecret(keyPair: CryptologyKeyPair): Promise<WebCryptoKey> {
        const keyDeriveAlgorithm: EcdhKeyDeriveParams = {
            name: 'ECDH',
            public: keyPair.publicKey.cryptoKey,
        };
        const derivedKeyType: AesDerivedKeyParams = {
            name: 'AES-GCM',
            length: 256,
        };
        const key = await crypto.subtle.deriveKey(
            keyDeriveAlgorithm,
            keyPair.privateKey.cryptoKey,
            derivedKeyType,
            true,
            ['wrapKey', 'unwrapKey']
        );

        return new SymmetricWebCryptoKey(key);
    }
}
