import { Injectable } from '@angular/core';

import { FileElement } from '../file-explorer/models/element';
import { AuthService } from '../authentication/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SharedService
{

  constructor() { }

  public theme: string;
  public currentRoot: FileElement;

  public setTheme(theme: string)
  {
    this.theme = theme;
  }

  public setCurrentRoot(currentRoot: FileElement) 
  {
    this.currentRoot = currentRoot;
  }

  // Check localStorage for special key
  public localStorageChecker(key_hint: string): any
  {
    let response: any;
    for (var i = 0; i < window.localStorage.length; i++)
    {
      var key = window.localStorage.key(i);
      if (key.includes(key_hint))
      {
        response = JSON.parse(window.localStorage.getItem(key));
        break;
      }
    }
    return response;
  }

  // Remove localStorage for special key
  public localStorageRemover(key_hint: string)
  {
    let response: any;
    for (var i = 0; i < window.localStorage.length; i++)
    {
      var key = window.localStorage.key(i);
      if (key.includes(key_hint))
      {
        window.localStorage.removeItem(key);
      }
    }
  }

  public cleanLocalStorage()
  {
    this.localStorageRemover('oidc');
    localStorage.removeItem('access_token');
    localStorage.removeItem('callbackRunning');
  }
}