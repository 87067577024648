import { Injectable } from '@angular/core';

import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class DarkmodeService
{

  private componentCssClass: any;

  constructor(
    private sharedService: SharedService
  ) { }

  public async initDarkMode(componentCssClass: any)
  {
    this.componentCssClass = componentCssClass;
    //console.log("<----- Check Theme ----->")
    if (localStorage.getItem("toggleTheme") != null)
    {
      if (localStorage.getItem("toggleTheme") == "Dark")
      {
        this.toggleTheme();
        this.sharedService.setTheme("Dark");
      } else
      {
        this.sharedService.setTheme("Light");
      }
    } else
    {
      this.sharedService.setTheme("Light");
    }
  }

  public async toggleTheme()
  {
    if (this.componentCssClass === 'dark-theme')
    {
      this.componentCssClass = '';
      document.body.classList.remove('dark-theme');
      localStorage.setItem("toggleTheme", "Light");
      this.sharedService.theme = "Light";
    } else
    {
      this.componentCssClass = 'dark-theme';
      document.body.classList.add('dark-theme');
      localStorage.setItem("toggleTheme", "Dark");
      this.sharedService.theme = "Dark";
    }
  }
}