import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss']
})
export class SortMenuComponent
{
  sortMode: string = localStorage.getItem('sortMode') || 'Name';
  sortDirection: string = localStorage.getItem('sortDirection') || 'ASC';

  @Output() sort = new EventEmitter<{ sortMode: string, sortDirection: string }>();

  sortby(sortBy: string, sortDirection: string)
  {
    this.sortDirection = sortDirection;
    this.sortMode = sortBy;

    localStorage.setItem('sortMode', sortBy);
    localStorage.setItem('sortDirection', this.sortDirection);
    this.sort.emit({ sortMode: this.sortMode, sortDirection: this.sortDirection });
  }
}


