import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform
{
  transform(items: any[], sortDirection: string, sortMode: string): any[]
  {
    // if no items are given, return empty list
    if (!items) return [];
    var files

    // if no sort mode is given, return all items
    switch (sortMode)
    {
      // sort by size
      case 'Größe': {
        // sort folders and files separately
        var folders = items.filter(it =>
        {
          return it.isFolder === true;
        }).sort((a, b) => a.name.localeCompare(b.name));

        files = items.filter(it =>
        {
          return it.isFolder != true;
        }).sort((a, b) => a.file_size - b.file_size);

        // return folders and files together
        if (sortDirection === 'ASC')
        {
          return folders.concat(files)
        } else
        {
          folders.reverse()
          return folders.concat(files.reverse())
        }
        break;
      }
      // sort by name
      default: {
        // sort folders and files separately
        var folders = items.filter(it =>
        {
          return it.isFolder === true;
        }).sort((a, b) => a.name.localeCompare(b.name));

        files = items.filter(it =>
        {
          return it.isFolder != true;
        }).sort((a, b) => a.name.localeCompare(b.name));

        // return folders and files together
        if (sortDirection === 'ASC')
        {
          return folders.concat(files)
        } else
        {
          folders.reverse()
          return folders.concat(files.reverse())
        }
      }
    }
  }
}