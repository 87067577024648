import { AsymmetricWebCrypto } from '../asymmetric/asymmetricCrypto';
import { TenantAndUserKeys } from '../model/crypto.model';
import { SymmetricWebCrypto } from '../symmetric/symmetricCrypto';
import { ArrayBufferUtil } from '../util/array-buffer-util';
import { WebCryptoKey } from '../webCryptoKey';

/**
 * WebCryptoKeyImporter provides a function to import the key material.
 */
export class WebCryptoKeyImporter {
    private readonly symmetricCrypto = new SymmetricWebCrypto();
    private readonly asymmetricCrypto = new AsymmetricWebCrypto();

    /**
     * Imports keys and finally decrypts the DMK by computing the shared secret and decryption of KEK
     * @param rawMaterial all four client-keys (public, private, DMK, KEK)
     * @param pekKey private symmetric key of client - used to encrypt/decrypt the privatKey
     * @returns imported symmetric key (DMK)
     */
    public async importKeyMaterial(
        rawMaterial: TenantAndUserKeys,
        pekKey: string
    ): Promise<WebCryptoKey> {
        // Import pek
        const pekBuffer = ArrayBufferUtil.toArrayBuffer(pekKey);
        const pek = await this.symmetricCrypto.import(pekBuffer, ['wrapKey', 'unwrapKey']);

        // Import public key of the owner
        const ownerPublicKeyBuffer = ArrayBufferUtil.toArrayBuffer(rawMaterial.publicKey);
        const publicKey = await this.asymmetricCrypto.import(ownerPublicKeyBuffer);

        // Import private key with pek
        const privateKeyBuffer = ArrayBufferUtil.toArrayBuffer(rawMaterial.encryptedPrivateKey);
        const privateKey = await this.asymmetricCrypto.import(privateKeyBuffer, pek.cryptoKey);

        // Generate shared secret with public and decrypted private key
        const sharedSecret = await this.asymmetricCrypto.generateSharedSecret({
            privateKey,
            publicKey,
        });

        // Decrypt and import KEK
        const encryptedKek = ArrayBufferUtil.toArrayBuffer(rawMaterial.encryptedKek);
        const kek = await this.symmetricCrypto.import(
            encryptedKek,
            ['wrapKey', 'unwrapKey'],
            sharedSecret
        );

        // Decrypt and import DMK
        const encryptedDmk = ArrayBufferUtil.toArrayBuffer(rawMaterial.encryptedDmk);
        const dmk = await this.symmetricCrypto.import(encryptedDmk, ['encrypt', 'decrypt'], kek);

        return dmk;
    }
}
