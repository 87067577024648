<!-- Title -->
<mat-toolbar>
  <h1 mat-dialog-title>Neuer Ordner</h1>
  <span class="vertical-spacer"></span>
  <button mat-icon-button mat-dialog-close>
    <i class="fa-light fa-circle-xmark"></i>
  </button>
</mat-toolbar>

<!-- Input for Folder Name -->
<mat-dialog-content>
  <mat-form-field class="example-full-width dialogContent">
    <input matInput placeholder="Ordner Name" [(ngModel)]="folderName" (ngModelChange)="folderNameChanged($event)" />
  </mat-form-field>
  <mat-error *ngIf="folderExists === true">
    Ein Ordner mit diesem Namen existiert bereits.
  </mat-error>
</mat-dialog-content>

<!-- Buttons for Actions -->
<mat-dialog-actions>
  <!-- Cancel Action to create a new folder -->
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <!-- Create a new folder with entered folder name -->
  <button *ngIf="folderExists != true" mat-raised-button [mat-dialog-close]="folderName" color="primary">OK</button>
  <button *ngIf="folderExists === true" mat-raised-button [mat-dialog-close]="folderName" disabled>OK</button>
</mat-dialog-actions>