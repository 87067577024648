<div class="uploader">
    <!-- ProgressBar to Show Progress of Upload -->
    <mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress" *ngIf="uploadProgress">

    </mat-progress-bar>

    <!-- Cancel Upload Button/Icon -->
    <mat-icon class="cancel-upload" (click)="cancelUpload()" *ngIf="uploadProgress">delete_forever</mat-icon>
    <!-- Input for FileChooser to select File to Upload -->
    <input type="file" id="file" class="file-input" accept={{requiredFileType}} (change)="onFileSelected($event)"
        #fileUpload multiple>

    <!-- Upload Button with Cloud_Upload Icon -->
    <button mat-mini-fab color="primary" class="upload-btn button" (click)="fileUpload.click()">
        <mat-icon>cloud_upload</mat-icon>
    </button>
</div>