import { Component } from '@angular/core';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent 
{
  constructor(
    public authService: AuthService
  ) { }

  //OnInit started at open site
  ngOnInit() 
  {
    console.log("<----- ngOnInit Login ----->");

    // Check if user is logged in
    if (!this.authService.isAuthorized && !this.authService.isLoggedIn)
    {
      this.authService.login();
    }
  }
}
