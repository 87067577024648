import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from 'src/app/material/material.module';

import { LoginComponent } from '../login/login.component';
import { CallbackComponent } from '../callback/callback.component';

@NgModule({
  declarations: [
    LoginComponent,
    CallbackComponent
  ],
  imports: [
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports: [
    LoginComponent
  ],
  entryComponents: [
    LoginComponent
  ],
})
export class LoginModule { }