<button mat-icon-button ngDefaultControl class="button" [matMenuTriggerFor]="sortMenu" [(ngModel)]="sort">
    <div class="sortButton">
        <mat-icon class="fa-light fa-filter"></mat-icon>
    </div>
</button>
<mat-menu class="matMenu" #sortMenu="matMenu" xPosition="before">
    <button mat-menu-item [matMenuTriggerFor]="name">Name</button>
    <button mat-menu-item [matMenuTriggerFor]="size">Größe</button>
    <!--<button mat-menu-item (click)="sortby('Typ')">Typ</button>-->
</mat-menu>

<mat-menu class="matMenu" #name="matMenu">
    <button mat-menu-item (click)="sortby('Name','ASC')">
        <mat-icon class="fa-light fa-arrow-down-a-z"></mat-icon>
        <span>Absteigend</span>
    </button>
    <button mat-menu-item (click)="sortby('Name','DESC')">
        <mat-icon class="fa-light fa-arrow-up-z-a"></mat-icon>
        <span>Aufsteigend</span>
    </button>
    <!--<button mat-menu-item (click)="sortby('Typ')">Typ</button>-->
</mat-menu>

<mat-menu class="matMenu" #size="matMenu">
    <button mat-menu-item (click)="sortby('Größe','ASC')">
        <mat-icon class="fa-light fa-arrow-down-small-big"></mat-icon>
        <span>Absteigend</span>
    </button>
    <button mat-menu-item (click)="sortby('Größe','DESC')">
        <mat-icon class="fa-light fa-arrow-up-big-small"></mat-icon>
        <span>Aufsteigend</span>
    </button>
    <!--<button mat-menu-item (click)="sortby('Typ')">Typ</button>-->
</mat-menu>