import { Injectable } from '@angular/core';

import { UserService } from 'src/app/authentication/services/user.service';
import { CryptoService } from './crypto.service';

import * as CryptoJS from "crypto-js";
import { ArrayBufferUtil } from 'src/lib/crypto/crypto';



@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService
{

  userKey: string = this.userService.userKey;
  
  constructor
    (
      private userService: UserService,
      private cryptoLib: CryptoService
    )
  { }


  /*---------------------------------------- FILE CRYPTO FUNCTIONS START ----------------------------------------*/

  // Encrypt the shared file with the new generated key from the shareFile() function
  async encryptSharedFile(file: File, key: string): Promise<File>
  {
    const chunkSize = 10 * 1024 * 1024; // 10 MB chunk size (adjust as needed)
  
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const byteArray = new Uint8Array(reader.result as ArrayBuffer);
  
        // Split the file content into chunks
        const numChunks = Math.ceil(byteArray.length / chunkSize);
        const encryptedChunks: { index: number; data: string }[] = [];
  
        for (let i = 0; i < numChunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(start + chunkSize, byteArray.length);
          const chunk = byteArray.slice(start, end);
  
          const wordArray = CryptoJS.lib.WordArray.create(chunk);
          const encrypted = CryptoJS.AES.encrypt(wordArray, key.trim()).toString();
          encryptedChunks.push({ index: i, data: encrypted });
        }
  
        // Create a new Blob object with the encrypted chunks
        const encryptedData = new Blob(encryptedChunks.map(chunk => chunk.data), { type: 'application/octet-stream' });

        // Create a new File object with the original file name and type
        const originalFileName = file.name;
        const originalExtension = originalFileName.substring(originalFileName.lastIndexOf('.'));
        const fileName = originalFileName.replace(originalExtension, '') + '.hasofile';
  
        const encryptedFile = new File([encryptedData], fileName, { type: 'application/octet-stream' });
        resolve(encryptedFile);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }


  // Chunk file, encrypt chunks, return File Object with encrypted chunks -- Limit ~80MB
  async encryptFile(file: File): Promise<File> {
    const chunkSize = 10 * 1024 * 1024; // 10 MB chunk size (adjust as needed)
  
    return new Promise<File>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const byteArray = new Uint8Array(reader.result as ArrayBuffer);
  
        // Split the file content into chunks
        const numChunks = Math.ceil(byteArray.length / chunkSize);
        const encryptedChunks: { index: number; data: string }[] = [];
  
        for (let i = 0; i < numChunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(start + chunkSize, byteArray.length);
          const chunk = byteArray.slice(start, end);
  
          const wordArray = CryptoJS.lib.WordArray.create(chunk);
          const encrypted = CryptoJS.AES.encrypt(wordArray, this.userService.userKey.trim()).toString();
          encryptedChunks.push({ index: i, data: encrypted });
        }
  
        // Create a new Blob object with the encrypted chunks
        const encryptedData = new Blob(encryptedChunks.map(chunk => chunk.data), { type: 'application/octet-stream' });
  
        // Create a new File object with the original file name and type
        const originalFileName = file.name;
        const originalExtension = originalFileName.substring(originalFileName.lastIndexOf('.'));
        const fileName = originalFileName.replace(originalExtension, '') + '.hasofile';
  
        const encryptedFile = new File([encryptedData], fileName, { type: 'application/octet-stream' });
        resolve(encryptedFile);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }


  // Read file content, decrypt chunks, return decrypted file
  async decryptFile(file: File): Promise<File> {
    const reader = new FileReader();
    const decryptedChunks: Uint8Array[] = [];
  
    return new Promise<File>((resolve, reject) => {
      reader.onload = () => {
        const encryptedArray = reader.result as string;
        const encryptedChunks = [];
        let decryptedContent = "";
        encryptedChunks.push(...encryptedArray.split("U2Fsd").slice(1)); // Fix: Spread the elements into encryptedChunks
        
        for (let i = 0; i < encryptedChunks.length; i++) {
          const encryptedChunk = "U2Fsd" + encryptedChunks[i]; // Prepend "U2" to the encrypted chunk
          const decrypted = CryptoJS.AES.decrypt(encryptedChunk, this.userService.userKey.trim());
          const decryptedChunkContent = decrypted.toString(CryptoJS.enc.Hex);
          const decryptedArray = new Uint8Array(decrypted.words);
          
          decryptedChunks.push(decryptedArray);
          decryptedContent += decryptedChunkContent;
        }
  
        const byteArray = new Uint8Array(decryptedContent.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
        const fileName = file.name.replace('.hasofile', '');
        const decryptedFile = new File([byteArray], fileName, { type: file.type });
        resolve(decryptedFile);
      };
  
      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
  

  // Decrypt the shared file with the provided key, no chunking functionality
  decryptSharedFile(encryptedFile: File, userKey: string): Promise<File>
  {
    const reader = new FileReader();
    const decryptedChunks: Uint8Array[] = [];
  
    return new Promise<File>((resolve, reject) => {
      reader.onload = () => {
        const encryptedArray = reader.result as string;
        const encryptedChunks = [];
        let decryptedContent = "";
        encryptedChunks.push(...encryptedArray.split("U2Fsd").slice(1)); // Fix: Spread the elements into encryptedChunks
        
        for (let i = 0; i < encryptedChunks.length; i++) {
          const encryptedChunk = "U2Fsd" + encryptedChunks[i]; // Prepend "U2" to the encrypted chunk
          const decrypted = CryptoJS.AES.decrypt(encryptedChunk, userKey.trim());
          const decryptedChunkContent = decrypted.toString(CryptoJS.enc.Hex);
          const decryptedArray = new Uint8Array(decrypted.words);
          
          decryptedChunks.push(decryptedArray);
          decryptedContent += decryptedChunkContent
        }
  
        const byteArray = new Uint8Array(decryptedContent.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
        const fileName = encryptedFile.name.replace('.hasofile', '');
        const decryptedFile = new File([byteArray], fileName, { type: encryptedFile.type });
        resolve(decryptedFile);
      };
  
      reader.onerror = reject;
      reader.readAsText(encryptedFile);
    });
  }
  /*---------------------------------------- FILE CRYPTO FUNCTIONS END ----------------------------------------*/


  /*---------------------------------------- FILE-STRUCTURE CRYPTO FUNCTIONS START ----------------------------------------*/
  // Function to encrypt a JSON string with AES-256
  encryptStructure(jsonString: string): string
  {
    const encrypted = CryptoJS.AES.encrypt(jsonString, this.userService.userKey.trim());
    return encrypted.toString();
  }
  
  encryptSharedStructure(jsonString: string, key: string): string
  {
    const encrypted = CryptoJS.AES.encrypt(jsonString, key);
    return encrypted.toString();
  }
  
  //Function to decrypt a JSON string with AES-256
  decryptStructure(encryptedString: string): string
  {
    const bytes = CryptoJS.AES.decrypt(encryptedString, this.userService.userKey.trim());
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  
  decryptSharedStructure(encryptedString: string, key: string): string
  {
    const bytes = CryptoJS.AES.decrypt(encryptedString, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  /*---------------------------------------- FILE-STRUCTURE CRYPTO FUNCTIONS END ----------------------------------------*/
}


