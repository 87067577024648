<div class="side">
    <div class="sidecontent">
        <router-outlet></router-outlet>
    </div>
    <div class="sidefooter">
        <mat-toolbar *ngIf="authService.isLoggedIn" class="footer">
            <div class="footerLogo">
                <!-- Logo and Title Left-->
                <a *ngIf="sharedService.theme=='Light'" href="https://hasomed.de/" target="_blank">
                    <img id="logoHeader" width="150" alt="HasomedLogo" src="../../../assets/img/logo1.png" />
                </a>
                <a *ngIf="sharedService.theme=='Dark'" href="https://hasomed.de/" target="_blank">
                    <img id="logoHeader" width="150" alt="HasomedLogo"
                        src="../../../assets/img/HASOMED_Logo_weiss_2.png" />
                </a>
            </div>
            <span class="vertical-spacer"></span>
            <div class="footerButtons" *ngIf="authService.isAuthorized && authService.isLoggedIn">
                <!-- THEME -->
                <button class="hasomed-button hasomed-button-white" id="darkModeSwitch"
                    (click)="darkmodeService.toggleTheme()" #darkModeSwitch>
                    <div class="hasomed-button-icon" *ngIf="sharedService.theme == 'Dark'">
                        <mat-icon class="fa-light fa-moon"></mat-icon>
                    </div>
                    <div class="hasomed-button-icon" *ngIf="sharedService.theme == 'Light'">
                        <mat-icon class="fa-light fa-sun"></mat-icon>
                    </div>
                    <div class="hasomed-button-text">
                        <span>{{sharedService.theme}}</span>
                    </div>
                </button>
                <!-- LOGOUT -->
                <button class="hasomed-button hasomed-button-red" (click)="authService.externalLogout()">
                    <div class="hasomed-button-icon">
                        <mat-icon class="fa-light fa-right-from-bracket"></mat-icon>
                    </div>
                    <div class="hasomed-button-text">
                        <span>Logout</span>
                    </div>
                </button>
            </div>
        </mat-toolbar>
    </div>
</div>