import { UserManager, User, Log } from 'oidc-client';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'src/configs/environment';
import { catchError, from, lastValueFrom, map, switchMap, throwError } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService 
{
  // User manager for OpenID Connect
  public userManager: UserManager;

  // User data
  public access_token: any;

  // User state
  public loading: boolean;
  public isLoggedIn: boolean = false;
  public isAuthorized: boolean = false;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private userService: UserService
  )
  {
    // User manager for OpenID Connect
    this.userManager = new UserManager(environment);

    Log.logger = console;
    Log.level = Log.DEBUG;
  }

  // login with localStorage and redirect to login page
  public login(): Promise<void>
  {
    localStorage.setItem('access_token', '')
    localStorage.setItem('callbackRunning', 'true')
    return this.userManager.signinRedirect();
  }

  // login with userManager redirect callback
  public async completeLogin(): Promise<User>
  {
    const user = await this.userManager.signinRedirectCallback();
    return user;
  }

  // logout with userManager
  public logout(): Promise<void>
  {
    return this.userManager.signoutRedirect();
  }

  // logout with userManager redirect callback
  public async completeLogout(): Promise<void>
  {
    await this.userManager.signoutRedirectCallback();
  }

  // Gets access token with authentication code and 
  public async getAccessToken(authorizationCode: string): Promise<any>
  {
    console.log("<----- getAccessToken called ----->");
    // get token endpoint url, redirect uri, client id and client secret from environment
    const tokenEndpointUrl = environment.authority + 'token-srv/token';
    const redirectUri = environment.redirect_uri;
    const clientId = environment.client_id;
    const clientSecret = environment.clientSecret;
    let codeVerifier = "";
    try
    {
      // get code verifier from AuthService
      codeVerifier = this.getCodeVerifier();
    } catch {
      // if code verifier does not exist, clean localStorage
      this.sharedService.cleanLocalStorage();
    }

    // set params for http post request
    const params = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('code', authorizationCode)
      .set('redirect_uri', redirectUri)
      .set('client_id', clientId)
      .set('client_secret', clientSecret)
      .set('code_verifier', codeVerifier);

    // set headers for http post request
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    let getAccess;
    try
    {
      getAccess = await this.checkAccess(tokenEndpointUrl, params, { headers });
    } catch {
      return false;
    }

    // set access callbackRunning in localStorage and return response
    localStorage.setItem('callbackRunning', 'false');
    return getAccess;
  }

  // Checks access token validation
  public async checkAccessToken(): Promise<boolean>
  {
    console.log("<----- checkAccessToken called ----->");

    // Variable for access token validation
    let accessTokenOk: boolean;
    let access_token: string;

    try
    {
      // try to get access token from localStorage
      access_token = localStorage.getItem('access_token');
      accessTokenOk = true;
    } catch {
      accessTokenOk = false;
      return accessTokenOk;
    }

    // set headers for http post request
    const httpOptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };

    // set body for http post request
    const body = new URLSearchParams();
    body.set('token', access_token);
    let checkedAccess;
    try
    {
      checkedAccess = await this.checkAccess(environment.authority + 'token-srv/introspect', body, httpOptions);
    } catch {
      return false;
    }

    // get current Unix timestamp
    const current_time = Math.floor(Date.now() / 1000); // Aktuellen Unix-Zeitstempel abrufen
    let timeLeft = checkedAccess.exp - current_time; // Restzeit des Tokens berechnen

    console.log("<----- Token expires: " + this.time_convert(timeLeft) + " ----->")

    // if access token is valid, set accessTokenOk to true
    if (checkedAccess.active != null)
    {
      accessTokenOk = true;
    } else
    {
      accessTokenOk = false;
    }
    return accessTokenOk;
  }

  private async checkAccess(url, body, httpOptions): Promise<any>
  {
    console.log(body.toString(), httpOptions)
    return await lastValueFrom(this.http.post<any>(url, body.toString(), httpOptions).pipe(
      catchError((error: HttpErrorResponse) =>
      {
        if (error.error instanceof ErrorEvent)
        {
          // Clientseitiger Fehler
          console.error('Ein Fehler ist aufgetreten:', error.error.message);
        } else
        {
          // Serverseitiger Fehler
          console.error(
            `Fehlercode ${error.status}, ` +
            `Fehlermeldung: ${error.error}`);
        }
        this.sharedService.cleanLocalStorage();
        // Rückgabe eines Observable mit einem benutzerdefinierten Fehlerobjekt
        return throwError('Etwas ist schief gelaufen. Bitte leeren Sie Ihren localStorage.(F12)');
      })
    ));
  }

  // Convertes a second number value to dd:hh:mm:ss
  private time_convert(sec: number)
  {
    let days = Math.floor(sec / 86400)
    sec %= 86400;
    let hours = Math.floor(sec / 3600);
    sec %= 3600;
    let minutes = Math.floor(sec / 60);
    let seconds = sec % 60;

    return days + "d:" + hours + "h:" + minutes + "m:" + seconds + "s";
  }

  // Logs out of Hasomed session and ends it
  public async hasomedLogout(access_token: string): Promise<string>
  {
    const queryString = '?access_token_hint=' + access_token + '&post_logout_redirect_uri=' + environment.post_logout_redirect_uri;

    try
    {
      const response = await fetch(environment.authority + 'session/end_session' + queryString, {
        method: 'POST',
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
      console.log(response);
      return response.url;
    } catch {
      return "Error";
    }
  }

  public async externalLogout()
  {
    this.isAuthorized = false;
    this.isLoggedIn = false;
    const access_token = (localStorage.getItem('access_token'));
    this.sharedService.cleanLocalStorage();
    const logoutRedirectURL = await this.hasomedLogout(access_token);
    window.location.href = logoutRedirectURL;
    //this.router.navigateByUrl('/');
  }

  // Gets the code verifier as response of hasomed authorization from localStorage
  public getCodeVerifier(): string
  {
    // get code verifier from localStorage by oidc.user
    let response: { code_verifier: string; };
    for (var i = 0; i < window.localStorage.length; i++)
    {
      var key = window.localStorage.key(i);
      if (key.slice(0, 4) === "oidc")
      {
        response = JSON.parse(window.localStorage.getItem(key));
        break;
      }
    }
    return response.code_verifier;
  }

  // Check if the user exists in the backend / create new user in the backend
  async checkUser()
  {
    // Get user ID and user mail
    const userID = await this.userService.getUserID();
    const userMail = await this.userService.getUserMail();

    const userFormData = new FormData();
    userFormData.append('mode', 'checkifuserexists');
    userFormData.append('user_id_ext', userID);
    userFormData.append('application_id', '0');

    try
    {
      const userExistResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", userFormData).toPromise();
      const userExistResponseNumber = Number(userExistResponse);
      console.log("Drive User Exists?", userExistResponse, userExistResponseNumber, typeof userExistResponseNumber);

      if (userExistResponseNumber === 0)
      {
        console.log("Creating new User")
        const userKey = await this.userService.genKey();
        const newToken = await this.userService.getNewToken();

        const newUserFormData = new FormData();
        newUserFormData.append('mode', 'newuser');
        newUserFormData.append('user_id_ext', this.userService.userID);
        newUserFormData.append('application_id', '0');
        newUserFormData.append('auth1', '0');
        newUserFormData.append('auth2', '0');
        newUserFormData.append('auth2', '0');
        newUserFormData.append('user_email', userMail);
        newUserFormData.append('primary_key', userKey);
        newUserFormData.append('primary_token', newToken);

        // const newUserResponse = 
        // console.log("Drive New User: ", newUserResponse);

        await this.http.post("https://nssks.impact-tec.com/nssks.php", newUserFormData).subscribe(
          () => {
            console.log("new User request done");
          },
          (error) => {
            console.error("Error creating new user:", error);
          }
        );
        return "New User created";
      } else
      {
        console.log("User already exists");
        return "User already exists";
      }
    } catch (error)
    {
      console.error("Error in checkUser:", error);
      throw error; // Rethrow the error to handle it in the calling code
    }
  }




  // async checkDriveUser()
  // {
  //   const userID = await this.userService.getUserID();
  //   const userMail = await this.userService.getUserMail();

  //   // Form data for user check
  //   const userFormData = new FormData();
  //   userFormData.append('mode', 'checkifuserexists');
  //   userFormData.append('user_id_ext', userID);
  //   userFormData.append('application_id', '0');


  //   // Post request to check if user exists
  //   const driveUserExistResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", userFormData).toPromise();
  //   console.log("Drive User Exists?", driveUserExistResponse);
  //   const driveUserExistResponseNumber = Number(driveUserExistResponse);

  //   // If user does not exist, create new user
  //   if (driveUserExistResponseNumber === 0)
  //   {
  //     const userKey = await this.userService.genKey();
  //     const newToken = await this.userService.getNewToken();

  //     const newUserFormData = new FormData();
  //     newUserFormData.append('mode', 'newuser');
  //     newUserFormData.append('user_id_ext', this.userService.userID);
  //     newUserFormData.append('application_id', '0');
  //     newUserFormData.append('auth1', '0');
  //     newUserFormData.append('auth2', '0');
  //     newUserFormData.append('auth2', '0');
  //     newUserFormData.append('user_email', userMail);
  //     newUserFormData.append('primary_key', userKey);
  //     newUserFormData.append('primary_token', newToken);

  //     // Post request to create new user
  //     const newUserResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", newUserFormData).toPromise();
  //     console.log("Drive New User: ", newUserResponse);
  //   }
  // }
}
