import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { FileElement } from '../models/element';

import { FileExplorerRouteComponent } from '../component/file-explorer/route/file-explorer-route.component';

import { FileService } from '../services/file.service';
import { SharedService } from 'src/app/services/shared.service';
import { UserService } from 'src/app/authentication/services/user.service';


@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit
{
  public fileElements: Observable<FileElement[]>;
  public fileId: string;
  public fileName: string;
  public selectedFileName: string;


  constructor(
    public fileService: FileService,
    private http: HttpClient,
    public sharedService: SharedService,
    private fileRoute: FileExplorerRouteComponent,
    private userService: UserService
  )
  { }

  ngOnInit(): void
  {
    this.fileService.fileId$.subscribe((fileId) =>
    {
      // Store the fileId value in a variable
      this.fileId = fileId;
    });
    this.fileService.fileName$.subscribe((fileName) =>
    {
      // Store the fileName value in a variable
      this.fileName = fileName;
    });
  }

  @Input()
  requiredFileType: string;
  uploadProgress: number;

  cancelUpload()
  {
    this.fileService.cancelUpload();
  }

  // run when file in FileChooser is selected
  onFileSelected(event: any)
  {
    for (let i = 0; i < event.target.files.length; i++)
    {
      var selectedfile = event.target.files[i];
      if (selectedfile)
      {
        console.log('File selected:', selectedfile.name);
        if (selectedfile.size <= 52428800)
        {
          console.log(selectedfile.name)
          this.fileService.upload(selectedfile, this.userService.userID, this.userService.userToken)
            .then(() =>
            {
              if (this.fileService.checkIfElementExists(selectedfile.name, this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root") === true)
              {
                var nameExists = true
                var int = 1
                do
                {
                  const fileExtension = selectedfile.name.match(/\.\w{1,}(?!.)/)
                  const fileName = selectedfile.name.replace(fileExtension?.toString(), '')
                  this.fileName = fileName + '(' + int + ')' + fileExtension
                  console.log(`A file with the name '${selectedfile.name}' already exists. File was renamed to '${this.fileName}'.`)
                  nameExists = this.fileService.checkIfElementExists(this.fileName, this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root")
                  int++
                } while (nameExists)
              } else
              {
                this.fileName = selectedfile.name
              }
              this.addFile({
                name: this.fileName,
                size: selectedfile.size,
                type: selectedfile.type,
                lastmodified: selectedfile.lastModified,
                uploaded: new Date().getTime()
              });
              this.selectedFileName = this.fileName;
              console.log('Uploader Component: Upload complete');
            })
            .catch((error) =>
            {
              console.error('Upload failed:', error);
            });
        } else
        {
          alert("Die maximale Dateigröße für den Upload beträgt 50MB. Reduzieren Sie die Größe Ihrer Datei oder wählen Sie eine andere Datei.");
        }
      }
    }
  }

  // Add File to fileService as Element
  addFile(file: { name: string, size: number, type: string, lastmodified: number, uploaded: number })
  {
    // Set parameters of the new file element
    this.fileService.add({
      isFolder: false,
      file_id: this.fileId,
      file_name: this.fileName,
      name: file.name,
      file_size: file.size,
      file_type: file.type,
      lastModified: file.lastmodified,
      uploaded: file.uploaded,
      parent: this.sharedService.currentRoot ? this.sharedService.currentRoot.id : "root",
    });
    this.fileRoute.updateFileElementQuery();
  }

}
