import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform
{
  transform(items: any[], searchText: string): any[]
  {
    // if no items are given, return empty list
    if (!items) return [];
    // if no search text is given, return all items
    if (!searchText) return items;
    // convert search text to lowercase
    searchText = searchText.toLowerCase();
    // return filtered items
    return items.filter(it =>
    {
      // convert item name to lowercase
      return it.name.toLowerCase().includes(searchText);
    });
  }
}