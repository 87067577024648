<!-- Title -->
<mat-toolbar>
  <h1 *ngIf="isFolder === true" mat-dialog-title>Ordner umbenennen</h1>
  <h1 *ngIf="isFolder != true" mat-dialog-title>Datei umbenennen</h1>
  <span class="vertical-spacer"></span>
  <button mat-icon-button mat-dialog-close>
    <i class="fa-light fa-circle-xmark"></i>
  </button>
</mat-toolbar>

<!-- Input for Element Name -->
<mat-dialog-content>
  <mat-form-field class="example-full-width dialogContent">
    <input *ngIf="isFolder === true" matInput placeholder="Ordner Name" [(ngModel)]="folderName"
      (ngModelChange)="folderNameChanged($event)" />
    <input *ngIf="isFolder != true" matInput placeholder="Datei Name" [(ngModel)]="fileName"
      (ngModelChange)="folderNameChanged($event)" />
  </mat-form-field>
  <span *ngIf="isFolder != true">{{' ' + fileExtension}}</span>
  <mat-error *ngIf="isFolder === true && folderExists === true">
    Ein Ordner mit diesem Namen existiert bereits.
  </mat-error>
  <mat-error *ngIf="isFolder != true && folderExists === true">
    Eine Datei mit diesem Namen existiert bereits.
  </mat-error>

</mat-dialog-content>

<!-- Buttons for Actions -->
<mat-dialog-actions>
  <!-- Cancel Action to rename a the element-->
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <!-- Rename the folder with entered name -->
  <button *ngIf="isFolder === true && folderExists != true" mat-raised-button [mat-dialog-close]="folderName"
    color="primary">OK</button>
  <button *ngIf="isFolder === true && folderExists === true" mat-raised-button [mat-dialog-close]="folderName"
    disabled>OK</button>
  <!-- Rename the file with entered name -->
  <button *ngIf="isFolder != true && folderExists != true" mat-raised-button
    [mat-dialog-close]="fileName + fileExtension" color="primary">OK</button>
  <button *ngIf="isFolder != true && folderExists === true" mat-raised-button
    [mat-dialog-close]="fileName + fileExtension" disabled>OK</button>
</mat-dialog-actions>