import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

import { environment } from 'src/configs/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService
{
  constructor(
    private http: HttpClient,
  ) { }

  public userID: string;
  public userToken: string;
  public backendToken: string;
  public backendKey: string;
  public userKey: string;
  public userMail: string
  public pek;

  /* ---------------------------------------- User Information Request START ---------------------------------------- */
  public async getUserInfo(access_token: string): Promise<string>
  {
    // http Options for the get user information request
    const httpOptions = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + access_token,
      }
    };

    // get user information
    const response = await lastValueFrom(this.http.get(environment.authority + 'users-srv/userinfo/', httpOptions));

    // set user information in local storage
    localStorage.setItem('user', JSON.stringify(response));

    // return user information as string
    return JSON.stringify(response);
  }
  /* ---------------------------------------- User Information Request END ---------------------------------------- */

  /* ---------------------------------------- UserData for Backend Access and file encryption / decryption START ---------------------------------------- */
  async getUserID(): Promise<string>
  {
    // get user id from user information
    const response = await this.getUserInfo(localStorage.getItem('access_token'));
    const jsonObject = JSON.parse(response);
    const lastUsedIdentityId = jsonObject.last_used_identity_id;
    this.userID = lastUsedIdentityId;
    return lastUsedIdentityId;
  }

  // get user mail from user information
  async getUserMail(): Promise<string>
  {
    const response = await this.getUserInfo(localStorage.getItem('access_token'));
    const jsonObject = JSON.parse(response);
    const userEmail = jsonObject.email;
    this.userMail = userEmail;
    return userEmail;
  }
  /* ---------------------------------------- UserData for Backend Access and file encryption / decryption END ---------------------------------------- */

  /* ------------------------------ KEYSTORE VON IMPACT START ------------------------------ */
  async genKey()
  {
    const keyFormData = new FormData()
    keyFormData.append('mode', 'genkey');

    // generate key
    const keyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, { responseType: 'text' }).toPromise();
    return keyResponse
  }

  async getToken()
  {
    const userID = this.userID
    const formData = new FormData()
    formData.append('mode', 'gettoken');
    formData.append('user_id_ext', userID);
    formData.append('application_id', '0');
    formData.append('auth1', '0');
    formData.append('auth2', '0');
    formData.append('auth3', '0');

    // get token
    const tokenResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", formData, { responseType: 'text' }).toPromise();
    this.userToken = tokenResponse.toString()
    // console.log("teokensresponse: ", tokenResponse);
    return this.userToken;
  }

  async getNewToken()
  {
    const regToken = "2CgH§atyRk3Jv47usGqa6KgVqxuj44jJzP2iVUNEF+9MeZFznT&3w2UtaQprEvBUqyiFA9Tc63idFhswv2Mb8BHgtjEGS"
    const userID = this.userID
    const formData = new FormData()
    formData.append('user_id_ext', userID);
    formData.append('reg_token', regToken);
    // formData.append('application_id', '0');

    // get new token
    const newTokenResponse = await this.http.post("/api/User", formData, { responseType: 'text' }).toPromise();
    this.userToken = newTokenResponse.toString();
    console.log("newTokenResponse: ", newTokenResponse, newTokenResponse.toString())
    return newTokenResponse;
  }

  async getPrimaryKey()
  {
    const userID = this.userID
    const formData = new FormData()
    formData.append('mode', 'getprimarykey');
    formData.append('user_id_ext', userID);
    formData.append('application_id', '0');
    formData.append('auth1', '0');
    formData.append('auth2', '0');
    formData.append('auth3', '0');

    // get primary key
    const keyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", formData, { responseType: 'text' }).toPromise();
    this.userKey = keyResponse.toString()
    return this.userKey;
  }

  /* ------------------------------ KEYSTORE VON IMPACT END ------------------------------ */


  // TODO Björn: Implementierung der Hasomed Crypto Library
  /* ---------------------------------------- HASOMED CRYPTO LIBRARY FUNCTIONS START ---------------------------------------- */
  // async generateKeys()
  // {
  //   console.log("User and Tenant Keys:");
  //   console.log(this.cryptoLib.generateTenantAndUserKeys());
  //   // const keyMaterial = await this.cryptoLib.generateTenantAndUserKeys();
  //   const keyMaterial = await this.cryptoLib.generateUserKeys();
  //   //this.pek = keyMaterial.pek;

  //   const formDataInternal = new FormData();
  //   formDataInternal.append('tenantID', "1");
  //   formDataInternal.append('userID', await this.getUserID());
  //   // formDataInternal.append('encryptedDmk', ArrayBufferUtil.toBase64(keyMaterial.encryptedDmk));
  //   // formDataInternal.append('encryptedKek', ArrayBufferUtil.toBase64(keyMaterial.encryptedKek));
  //   formDataInternal.append('encryptedPrivateKey', ArrayBufferUtil.toBase64(keyMaterial.privateKey));
  //   formDataInternal.append('publicKey', ArrayBufferUtil.toBase64(keyMaterial.publicKey));

  //   // console.log(formDataInternal);

  //   fetch('http://localhost/KeystoreTest/keyStorePostAPI.php', {
  //     method: 'POST',
  //     body: formDataInternal
  //   })
  //     .then(response =>
  //     {
  //       if (response.ok)
  //       {
  //         console.log('Internal Keys added to Keystore successfully.');
  //       }

  //       else
  //       {
  //         console.error('Failed to add keys to Keystore.');
  //       }
  //     })
  //     .catch(error =>
  //     {
  //       console.error('Error:', error);
  //     });


  //   const formDataExternal = new FormData();
  //   formDataExternal.append('userID', await this.getUserID());
  //   formDataExternal.append('pekKey', ArrayBufferUtil.toBase64(keyMaterial.pek));

  //   fetch('http://localhost/KeystoreTest/keyStoreExtPostAPI.php', {
  //     method: 'POST',
  //     body: formDataExternal
  //   })
  //     .then(response =>
  //     {
  //       if (response.ok) 
  //       {
  //         console.log('PEK Key added to Keystore successfully.');
  //         // this.getKeys();
  //       }

  //       else 
  //       {
  //         console.error('Failed to add keys to Keystore.');
  //       }
  //     })
  //     .catch(error =>
  //     {
  //       console.error('Error:', error);
  //     });

  //   //this.getKeys();
  // }

  // async getKeys()
  // {
  //   const userID = this.userID;
  //   const internalUrl = `http://localhost/KeystoreTest/keyStoreGetAPI.php?userID=${encodeURIComponent(userID)}`;
  //   const externalUrl = `http://localhost/KeystoreTest/keyStoreExtGetAPI.php?userID=${encodeURIComponent(userID)}`;

  //   let tenantAndUserKeys;
  //   let pek;


  //   fetch(internalUrl)
  //     .then(response =>
  //     {
  //       if (response.ok)
  //       {
  //         return response.json();
  //       }

  //       else
  //       {
  //         console.error('Failed to retrieve keys from Keystore.');
  //         throw new Error('Failed to retrieve keys from Keystore.');
  //       }
  //     })
  //     .then(keys =>
  //     {
  //       if (keys)
  //       {
  //         const encryptedDmk = keys.encryptedDmk;
  //         const encryptedKek = keys.encryptedKek;
  //         const publicKey = keys.publicKey;
  //         const encryptedPrivateKey = keys.encryptedPrivateKey;

  //         tenantAndUserKeys = { encryptedDmk, encryptedKek, encryptedPrivateKey, publicKey };
  //         console.log("Internal Keys: ", tenantAndUserKeys);
  //       }

  //       else
  //       {
  //         console.error('No keys found for the specified userID.');
  //         //this.generateKeys();
  //       }
  //     })
  //     .catch(error =>
  //     {
  //       console.error('Error:', error);
  //     });


  //   fetch(externalUrl)
  //     .then(response =>
  //     {
  //       if (response.ok)
  //       {
  //         return response.json();
  //       }

  //       else
  //       {
  //         console.error('Failed to retrieve keys from Keystore.');
  //         throw new Error('Failed to retrieve keys from Keystore.');
  //       }
  //     })
  //     .then(pekKey =>
  //     {
  //       console.log(pekKey);
  //       if (pekKey.length > 0)
  //       {
  //         const key = pekKey[0];
  //         pek = key.pekKey;
  //         console.log("Tenant and User Keys: ", pek, tenantAndUserKeys);

  //         this.cryptoLib.importKeyMaterial(tenantAndUserKeys, pek);
  //         console.log("Keys imported.");
  //       }

  //       else
  //       {
  //         console.error('No keys found for the specified userID.');
  //       }
  //     })
  //     .catch(error =>
  //     {
  //       console.error('Error:', error);
  //     });
  // }
  /* ---------------------------------------- HASOMED CRYPTO LIBRARY FUNCTIONS END ---------------------------------------- */
}