/**
 * Handle conversions, data encoding and decoding.
 */
export class ArrayBufferUtil {
    /**
     * Convert string to 8 bit unsigned integer array buffer.
     *
     * @param base64
     * @returns 8 bit integer array buffer
     */
    public static toArrayBuffer(base64: string): ArrayBuffer {
        let binary_string = atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }

        return bytes.buffer;
    }

    /**
     * Convert 8 bit unsigned integer array buffer to string.
     *
     * @param buffer
     * @returns string
     */
    public static toBase64(buffer: ArrayBuffer): string {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return btoa(binary);
    }
}
