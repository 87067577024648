<!-- Toolbar above the Explorer Content -->
<mat-toolbar>
  <mat-toolbar-row>
    <!--<button mat-icon-button (click)="toggleSidenav()"><mat-icon>menu</mat-icon></button>-->
    <span style="margin-left: 8px" class="pointer" (click)="navigateToRoot()">Meine Dateien</span>
    <mat-icon *ngIf="canNavigateUp">keyboard_arrow_right</mat-icon>
    <span *ngIf="canNavigateUp" class="pointer" (click)="navigateUp()">{{path || 'Meine Dateien'}}</span>
    <span class="vertical-spacer"></span>
    <!-- UPLOAD -->
    <button class="hasomed-button hasomed-iconbutton" (click)="openUploadDialog()">
      <div class="hasomed-button-icon">
        <mat-icon class="fa-light fa-upload"></mat-icon>
      </div>
    </button>
    <!-- NEW FOLDER -->
    <button class="hasomed-button hasomed-iconbutton" (click)="openNewFolderDialog()">
      <div class="hasomed-button-icon">
        <mat-icon class="fa-light fa-folder-plus"></mat-icon>
      </div>
    </button>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <div class="search-block content-module">
      <mat-icon class="fa-light fa-magnifying-glass"></mat-icon>
      <input class="search-control content-module input" type="text" placeholder="Suchen" [(ngModel)]="searchText"
        #searchbar>
    </div>
    <span class="vertical-spacer"></span>
    <app-sort-menu (sort)="setCurrentSort($event)"></app-sort-menu>
    <button *ngIf="this.viewMode == 'list' " mat-icon-button id="view_module" (click)="toggleView('module')"
      aria-label="view_module">
      <mat-icon class="fa-light fa-list"></mat-icon>
    </button>
    <button *ngIf="this.viewMode == 'module'" mat-icon-button id="view_list" (click)="toggleView('list')"
      aria-label="view_list">
      <mat-icon class="fa-light fa-grid"></mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>


<!-- Generate Explorer Content for Elements -->
<div class="container" fxFlex fxLayout="row" fxLayoutAlign="space-between stretch" appDnd
  (fileDropped)="onFileDropped($event)">
  <!-- Dropzone zu fehleranfällig. Dropzone beschränkt auf Upload Dialog.-->
  <!-- <div class="dropzone">
<div class="dropzone-content">
<div class="background">
<img src="assets/img/cloud-computing.png" draggable="false" alt="">
<h3>Drag and drop Datei hier</h3>
</div>
</div>
</div> -->
  <!-- VIEWMODE MODULE -->
  <div *ngIf="this.viewMode == 'module'" class="content-module" fxFlex fxLayout="row"
    (window:resize)="onResize($event)">
    <mat-grid-list class="matGridList" [cols]="breakpoint" rowHeight="100px" fxFlex>
      <mat-grid-tile
        *ngFor="let element of fileElements | filter: searchText | sort: this.currentSortDirection : this.currentSortMode"
        class=file-or-folder title={{element.name}}>
        <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
          #menuTrigger="matMenuTrigger"></span>
        <div class="icons pointer" *ngIf="element.isFolder; else downloadLinkModule" (click)="navigate(element)"
          (contextmenu)="openMenu($event, menuTrigger)">
          <div matListItemIcon>
            <img width="48" src="assets/icons/file-type-folder.svg" draggable="false" matListItemIcon>
          </div>
          <span>{{element.name}}</span>
        </div>

        <ng-template #downloadLinkModule>
          <div class="icons pointer" (click)="openMenu($event, menuTrigger)"
            (contextmenu)="openMenu($event, menuTrigger)">
            <div [ngSwitch]="element.file_type">
              <img *ngSwitchCase="'application/pdf'" src="assets/icons/file-type-pdf-adobe-acrobat.svg"
                draggable="false" matListItemIcon>
              <img *ngSwitchCase="'application/x-zip-compressed'" src="assets/icons/file-type-zip-compressed.svg"
                draggable="false" matListItemIcon>
              <img *ngSwitchCase="'application/zip'" src="assets/icons/file-type-zip-compressed.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'application/zip'" src="assets/icons/file-type-zip-compressed.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'application/msword'" src="assets/icons/file-type-doc-word-document.svg"
                draggable="false" matListItemIcon>
              <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'"
                src="assets/icons/file-type-doc-word-document.svg" draggable="false" matListItemIcon>
              <img *ngSwitchCase="'text/css'" src="assets/icons/file-type-css-styles.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'text/plain'" src="assets/icons/file-type-txt-text-textedit.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'image/jpeg'" src="assets/icons/file-type-jpg-jpeg-image.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'image/png'" src="assets/icons/file-type-png-image.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'video/x-msvideo'" src="assets/icons/file-type-avi-video-movie.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'application/x-msdownload'||'application/octet-stream'"
                src="assets/icons/file-type-dll.svg" draggable="false" matListItemIcon>
              <img *ngSwitchCase="'application/postscript'" src="assets/icons/file-type-eps.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'text/html'" src="assets/icons/file-type-htm-web-page.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'video/quicktime'" src="assets/icons/file-type-mov-movie-video.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'audio/mpeg'" src="assets/icons/file-type-mp3-audio-music.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'application/vnd.ms-powerpoint'" src="assets/icons/file-type-ppt-powerpoint.svg"
                draggable="false" matListItemIcon>
              <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                src="assets/icons/file-type-ppt-powerpoint.svg" draggable="false" matListItemIcon>
              <img *ngSwitchCase="'image/vnd.adobe.photoshop'" src="assets/icons/file-type-psd-adobe-photoshop.svg"
                draggable="false" matListItemIcon>
              <img *ngSwitchCase="'audio/x-wav'" src="assets/icons/file-type-wav-wave-music-audio.svg" draggable="false"
                matListItemIcon>
              <img *ngSwitchCase="'application/vnd.ms-excel'" src="assets/icons/file-type-xls-excel-microsoft.svg"
                draggable="false" matListItemIcon>
              <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                src="assets/icons/file-type-xls-excel-microsoft.svg" draggable="false" matListItemIcon>
              <img *ngSwitchDefault width="48" src="assets/icons/file-type-unknown-question-mark.svg" draggable="false"
                matListItemIcon>
            </div>
            <span>{{element.name}}</span>
          </div>
        </ng-template>
      </mat-grid-tile>
    </mat-grid-list>
  </div>


  <!-- VIEWMODE LIST -->
  <div *ngIf="this.viewMode == 'list'" class="content-list" fxFlex fxLayout="column">
    <mat-list>
      <div class="listItem"
        *ngFor="let element of fileElements | filter: searchText | sort: this.currentSortDirection : this.currentSortMode">
        <span [matMenuTriggerFor]="rootMenu" [matMenuTriggerData]="{element: element}"
          #menuTrigger="matMenuTrigger"></span>
        <mat-list-item class="list-item-alignment" *ngIf="element.isFolder; else downloadLinkList"
          (click)="navigate(element)" (contextmenu)="openMenu($event, menuTrigger)">
          <div matListItemIcon style="margin-bottom: 25px;">
            <img width="48" src="assets/icons/file-type-folder.svg" draggable="false">
          </div>
          <span matListItemTitle>{{element.name}}</span>
        </mat-list-item>

        <ng-template #downloadLinkList>
          <mat-list-item class="list-item-alignment" (click)="openMenu($event, menuTrigger)"
            (contextmenu)="openMenu($event, menuTrigger)">
            <div [ngSwitch]="element.file_type" matListItemIcon>
              <img *ngSwitchCase="'application/pdf'" width="48" src="assets/icons/file-type-pdf-adobe-acrobat.svg"
                draggable="false">
              <img *ngSwitchCase="'application/x-zip-compressed'" width="48"
                src="assets/icons/file-type-zip-compressed.svg" draggable="false">
              <img *ngSwitchCase="'application/zip'" width="48" src="assets/icons/file-type-zip-compressed.svg"
                draggable="false">
              <img *ngSwitchCase="'application/zip'" width="48" src="assets/icons/file-type-zip-compressed.svg"
                draggable="false">
              <img *ngSwitchCase="'application/msword'" width="48" src="assets/icons/file-type-doc-word-document.svg"
                draggable="false">
              <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" width="48"
                src="assets/icons/file-type-doc-word-document.svg" draggable="false">
              <img *ngSwitchCase="'text/css'" width="48" src="assets/icons/file-type-css-styles.svg" draggable="false">
              <img *ngSwitchCase="'text/plain'" width="48" src="assets/icons/file-type-txt-text-textedit.svg"
                draggable="false">
              <img *ngSwitchCase="'image/jpeg'" width="48" src="assets/icons/file-type-jpg-jpeg-image.svg"
                draggable="false">
              <img *ngSwitchCase="'image/png'" width="48" src="assets/icons/file-type-png-image.svg" draggable="false">
              <img *ngSwitchCase="'video/x-msvideo'" width="48" src="assets/icons/file-type-avi-video-movie.svg"
                draggable="false">
              <img *ngSwitchCase="'application/x-msdownload'||'application/octet-stream'" width="48"
                src="assets/icons/file-type-dll.svg" draggable="false">
              <img *ngSwitchCase="'application/postscript'" width="48" src="assets/icons/file-type-eps.svg"
                draggable="false">
              <img *ngSwitchCase="'text/html'" width="48" src="assets/icons/file-type-htm-web-page.svg"
                draggable="false">
              <img *ngSwitchCase="'video/quicktime'" width="48" src="assets/icons/file-type-mov-movie-video.svg"
                draggable="false">
              <img *ngSwitchCase="'audio/mpeg'" width="48" src="assets/icons/file-type-mp3-audio-music.svg"
                draggable="false">
              <img *ngSwitchCase="'application/vnd.ms-powerpoint'" width="48"
                src="assets/icons/file-type-ppt-powerpoint.svg" draggable="false">
              <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.presentationml.presentation'"
                width="48" src="assets/icons/file-type-ppt-powerpoint.svg" draggable="false">
              <img *ngSwitchCase="'image/vnd.adobe.photoshop'" width="48"
                src="assets/icons/file-type-psd-adobe-photoshop.svg" draggable="false">
              <img *ngSwitchCase="'audio/x-wav'" width="48" src="assets/icons/file-type-wav-wave-music-audio.svg">
              <img *ngSwitchCase="'application/vnd.ms-excel'" width="48"
                src="assets/icons/file-type-xls-excel-microsoft.svg" draggable="false">
              <img *ngSwitchCase="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" width="48"
                src="assets/icons/file-type-xls-excel-microsoft.svg" draggable="false">
              <img *ngSwitchDefault width="48" src="assets/icons/file-type-unknown-question-mark.svg" draggable="false">
            </div>
            <span matListItemTitle>{{element.name}}</span>
            <span matListItemLine>
              Änderungsdatum: {{element.lastModified | date:'dd MMMM yyyy HH:mm'}}
            </span>
            <span matListItemLine *ngIf="element.file_size < 1000">Größe: 1kb</span>
            <span matListItemLine *ngIf="element.file_size >= 1000 && element.file_size <= 1000000">Größe:
              {{math.round(element.file_size/1000)}}kb</span>
            <span matListItemLine *ngIf="element.file_size >= 1000000 && element.file_size <= 1000000000">Größe:
              {{math.round(element.file_size/1000000)}}mb</span>
            <span matListItemLine *ngIf="element.file_size >= 1000000000">Größe:
              {{math.round(element.file_size/1000000000)}}gb</span>
          </mat-list-item>
        </ng-template>

      </div>
    </mat-list>
  </div>
</div>


<!-- Declare Menu for RightClick on Element -->
<mat-menu class="matMenu" #rootMenu="matMenu" [overlapTrigger]="false">
  <ng-template matMenuContent let-element="element">
    <!-- "Move to" Button for RightClickMenu -->
    <button mat-menu-item [matMenuTriggerFor]="moveToMenu" [matMenuTriggerData]="{self: element}">
      <mat-icon class="fa-light fa-arrows-up-down-left-right"></mat-icon>
      <span>Verschieben</span>
    </button>
    <!-- "Download" Button for RightClickMenu -->
    <button *ngIf="element.isFolder == false" mat-menu-item (click)="download(element)">
      <mat-icon class="fa-light fa-file-arrow-down"></mat-icon>
      <span>Download</span>
    </button>
    <button *ngIf="element.isFolder" mat-menu-item (click)="downloadFolder(element)">
      <mat-icon class="fa-light fa-folder-arrow-down"></mat-icon>
      <span>Download</span>
    </button>
    <!-- "Share" Button for RightClickMenu -->
    <button *ngIf="element.isFolder == false" mat-menu-item
      (click)="openShareDialog(element.file_id, element.name, element)">
      <mat-icon class="fa-light fa-share"></mat-icon>
      <span>Teilen</span>
    </button>
    <button *ngIf="element.isFolder == true" mat-menu-item (click)="openShareDialog(element.id, element.name, element)">
      <mat-icon class="fa-light fa-share"></mat-icon>
      <span>Teilen</span>
    </button>
    <!-- "Favorite" Button for RightClickMenu -->
    <button mat-menu-item (click)="favoriteElement(element)">
      <mat-icon *ngIf="!element.favorite" class="fa-light fa-star"></mat-icon>
      <mat-icon *ngIf="element.favorite" class="fa-solid fa-star"></mat-icon>
      <span>Favorit</span>
    </button>
    <!-- "Rename" Button for RightClickMenu -->
    <button mat-menu-item (click)="openRenameDialog(element)">
      <mat-icon class="fa-light fa-pen"></mat-icon>
      <span>Umbenennen</span>
    </button>
    <!-- "Delete" Button for RightClickMenu -->
    <button mat-menu-item (click)="removeElement(element)">
      <mat-icon class="fa-light fa-trash"></mat-icon>
      <span>Löschen</span>
    </button>
  </ng-template>
</mat-menu>


<!-- Generate Menu for each Element -->
<mat-menu class="matMenu" #moveToMenu="matMenu">
  <ng-template matMenuContent let-self="self">
    <button *ngIf="self.parent != 'root'" mat-menu-item (click)="moveElementUp(self, self.parent)">...</button>
    <ng-container *ngFor="let element of fileElements">
      <button *ngIf="element.isFolder && element.id !== self.id" mat-menu-item (click)="moveElement(self, element)">
        <mat-icon class="fa-light fa-folder"></mat-icon>
        {{element.name}}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>