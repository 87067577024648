import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'

import { FileExplorerComponent } from '../component/file-explorer/file-explorer.component'
import { FileExplorerRouteComponent } from '../component/file-explorer/route/file-explorer-route.component'
import { SharedComponent } from '../component/shared/shared.component'
import { FavoritesComponent } from '../component/favorites/favorites.component'
import { TrashComponent } from '../component/trash/trash.component'
import { NewFolderDialogComponent } from '../dialogs/new-folder-dialog/new-folder-dialog.component'
import { RenameDialogComponent } from '../dialogs/rename-dialog/rename-dialog.component'
import { ShareDialogComponent } from '../dialogs/share-dialog/share-dialog.component'
import { UploaderComponent } from '../uploader/uploader.component'
import { SortMenuComponent } from '../component/sort-menu/sort-menu.component'
import { UploadDialogComponent } from '../dialogs/upload-dialog/upload-dialog.component'
import { DndDirective } from '../directive/dnd.directive'

import { MaterialModule } from 'src/app/material/material.module'
import { FilterPipe } from 'src/pipes/filter.pipe'
import { SortPipe } from 'src/pipes/sorts.pipe'
import { AppRoutingModule } from 'src/app/app-routing.module'

@NgModule({
  declarations: [
    FileExplorerRouteComponent,
    FileExplorerComponent,
    FavoritesComponent,
    TrashComponent,
    SharedComponent,
    NewFolderDialogComponent,
    ShareDialogComponent,
    RenameDialogComponent,
    UploadDialogComponent,
    UploaderComponent,
    SortMenuComponent,
    DndDirective,
    FilterPipe,
    SortPipe
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule
  ],
  exports: [
    FileExplorerComponent
  ],
  entryComponents: [
    FileExplorerComponent,
    NewFolderDialogComponent,
    RenameDialogComponent,
    UploadDialogComponent,
    UploaderComponent,
    SortMenuComponent,
    DndDirective
  ],
  providers: [
    FileExplorerRouteComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class FileExplorerModule { }