import { Injectable } from '@angular/core'
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, Subscription, map, share } from 'rxjs'
import { v4 } from 'uuid'

import { FileElement } from '../models/element';

import { SharedService } from 'src/app/services/shared.service';
import { AESEncryptDecryptService } from './aesencrypt-decrypt.service';
import { UserService } from 'src/app/authentication/services/user.service';
import * as JSZip from 'jszip';
import { TrackerService } from 'src/app/services/tracker.service';
import { CryptoService } from './crypto.service';
import { ArrayBufferUtil } from 'src/lib/crypto/crypto';
import { json } from 'stream/consumers';
import { response } from 'express';
import { resolve } from 'path';

export interface IFileService
{
  // Declare Functions for Interface
  add(fileElement: FileElement): FileElement;
  delete(id: string);
  update(id: string, update: Partial<FileElement>);
  queryInFolder(folderId: string): Observable<FileElement[]>
  get(id: string): FileElement
}

@Injectable()
export class FileService implements IFileService
{
  //Variables
  public map = new Map<string, FileElement>()
  public resultMap = new Map<string, FileElement>();


  public fileId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public fileName$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public targetUserID: string;

  private sharedUserID: string;
  private sharedUserToken: string;
  private sharedUserKey: string;

  public uploadProgress: number;
  private uploadSub: Subscription;

  private currentRoot: FileElement;
  private fileElement: FileElement;

  //API URLs for Upload, Download and Structure
  private uploadApiURL: string          = "/api/upload";
  private downloadApiURL: string        = "/api/download";
  private deleteApiURL: string          = "/api/delete";
  private restoreApiURL: string         = "/api/restore";
  private shareApiURL: string           = "/api/share";
  private structureApiURL: string       = "/api/Structure";
  private structureShareApiURL: string  = "/api/structure_share";

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private cryptoService: AESEncryptDecryptService,
    private userService: UserService,
    private trackerService: TrackerService,
    private cryptoLib: CryptoService
  )
  { }


  /*----------------------------------------FILE-EXPLORER FUNCTIONS START----------------------------------------*/
  // Generate an ID for fileElement and add this to map
  add(fileElement: FileElement)
  {
    console.log("<----- fileService.add called ----->");
    fileElement.id = v4();
    console.log("Neuen Ordner/File erstellt: ", fileElement);
    this.map.set(fileElement.id, this.clone(fileElement));
    console.log(this.userService.userID);
    return fileElement
  }

  // Delete fileElement by ID
  delete(id: string)
  {
    this.map.delete(id)
  }

  deleteShared(id: string)
  {
    this.resultMap.delete(id);
  }

  // Delete File from Backend
  deleteFromBackend(file_id: string)
  {
    const deleteFormData = new FormData();
    deleteFormData.append('user_id_ext', this.userService.userID);
    deleteFormData.append('token', this.userService.userToken);
    deleteFormData.append('file_id', file_id)
    const deleteResponse = this.http.post(this.deleteApiURL, deleteFormData, { responseType: 'text' }).toPromise();
  }

  // Restore Notice for Backend
  restoreFile(file_id: string)
  {
    const restoreFormData = new FormData();
    restoreFormData.append('user_id_ext', this.userService.userID);
    restoreFormData.append('token', this.userService.userToken);
    restoreFormData.append('file_id', file_id)
    const deleteResponse = this.http.post(this.restoreApiURL, restoreFormData, { responseType: 'text' }).toPromise();
  }

  // Get Element and update it
  update(id: string, update: Partial<FileElement>)
  {
    let element = this.map.get(id)
    element = Object.assign(element, update)
    this.map.set(element.id, element)
  }

  // Update Elements and return it as Observable
  private querySubject: BehaviorSubject<FileElement[]>
  queryInFolder(folderId: string)
  {
    const result: FileElement[] = []
    this.map.forEach(element =>
    {
      if (element.parent === folderId)
      {
        if (!element.trash)
        {
          result.push(this.clone(element))
        }
      }
    })
    if (!this.querySubject)
    {
      this.querySubject = new BehaviorSubject(result)
    }
    else
    {
      this.querySubject.next(result)
    }
    return this.querySubject.asObservable()
  }

  // Update Elements and return it as Observable
  private querySharedSubject: BehaviorSubject<FileElement[]>
  queryInFolderShared(folderId: string)
  {
    const result: FileElement[] = []
    this.resultMap.forEach(element =>
    {
      if (element.parent === folderId)
      {
        if (!element.trash)
        {
          result.push(this.clone(element))
        }
      }
    })
    if (!this.querySharedSubject)
    {
      this.querySharedSubject = new BehaviorSubject(result)
    }
    else
    {
      this.querySharedSubject.next(result)
    }
    return this.querySharedSubject.asObservable()
  }

  // Update Elements and return it as Observable
  private queryFavSubject: BehaviorSubject<FileElement[]>
  queryInFolderFavorites(folderId: string)
  {
    const result: FileElement[] = []
    this.map.forEach(element =>
    {
      if (element.parent === folderId)
      {
        if (element.favorite && !element.trash)
          if (element.favorite && !element.trash)
          {
            result.push(this.clone(element))
          }
      }
    })
    if (!this.queryFavSubject)
    {
      this.queryFavSubject = new BehaviorSubject(result)
    }
    else
    {
      this.queryFavSubject.next(result)
    }
    return this.queryFavSubject.asObservable()
  }

  // Update Elements and return it as Observable
  private queryTrashSubject: BehaviorSubject<FileElement[]>
  queryInFolderTrash(folderId: string)
  {
    const result: FileElement[] = []
    this.map.forEach(element =>
    {
      if (element.parent === folderId)
      {
        if (element.trash)
        {
          result.push(this.clone(element))
        }
      }
    })
    if (!this.queryTrashSubject)
    {
      this.queryTrashSubject = new BehaviorSubject(result)
    }
    else
    {
      this.queryTrashSubject.next(result)
    }
    return this.queryTrashSubject.asObservable()
  }

  checkIfElementExists(newElementName: string, elementId: string)
  {
    var elementExists = 0
    this.map.forEach(element =>
    {
      if (element.name.toLocaleLowerCase() === newElementName.toLocaleLowerCase() && (element.parent === elementId))
      {
        elementExists++
      }
    })
    if (elementExists > 0)
    {
      return true
    } else
    {
      return false
    }
  }


  // Get Element by ID
  get(id: string)
  {
    return this.map.get(id)
  }

  // Clone Element
  clone(element: FileElement)
  {
    return JSON.parse(JSON.stringify(element))
  }

  onFileSelected(filesevent)
  {
    // getting a reference to the files that the user selected by accessing the event.target.files property
    if (filesevent.target.files[0] != null)
    {
      const selectedfiles: File = filesevent.target.files[0];
      this.upload(selectedfiles, this.userService.userID, this.userService.userToken);
    }
    else
    {
      const selectedfiles = filesevent.dataTransfer.files;
      this.upload(selectedfiles, this.userService.userID, this.userService.userToken);
    }
  }
  /*---------------------------------------- FILE-EXPLORER FUNCTIONS END ----------------------------------------*/


  /*---------------------------------------- FILESHARE FUNCTIONS START ----------------------------------------*/
  // Share the file data to the target user 
  async shareFile(file_id: string, element: any, targetName: string)
  {
    if (element.isFolder === false)
    {
      //Generate Shared Key
      const newSharedKeyFormData = new FormData()
      newSharedKeyFormData.append('mode', 'newsharedkey');
      newSharedKeyFormData.append('user_id_ext', this.userService.userID);
      newSharedKeyFormData.append('auth1', '0');
      newSharedKeyFormData.append('auth2', '0');
      newSharedKeyFormData.append('auth3', '0');
      newSharedKeyFormData.append('user_email', targetName);
      newSharedKeyFormData.append('reference', file_id);

      const newSharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", newSharedKeyFormData, { responseType: 'text' }).toPromise();

      const decryptedFile = await this.downloadAndDecryptFile(element);

      const reuploadResponse = await this.uploadSharedFile(decryptedFile, newSharedKeyResponse);
      const newFileId = reuploadResponse

      if (decryptedFile instanceof File)
      {
        decryptedFile.arrayBuffer().then(buffer =>
        {
          // Clear the ArrayBuffer to free up memory
          buffer = null;
          // console.log("Buffer cleared");
        });
      }

      const shareFormData = new FormData()
      shareFormData.append('user_id_ext', this.userService.userID);
      shareFormData.append('token', this.userService.userToken);
      shareFormData.append('file_id', newFileId);
      shareFormData.append('email', targetName);

      const sharePostData = await this.http.post(this.shareApiURL, shareFormData, { responseType: 'text' }).toPromise();

      // Encrypt Shared File Structure
      const shareStructure = this.map.get(element.id);
      shareStructure.file_id = newFileId;
      shareStructure.old_file_id = file_id;
      const updatedShareStructure = JSON.stringify(shareStructure);

      const structureFormData = new FormData()
      structureFormData.append('mode', 'new');
      structureFormData.append('user_id_ext', this.userService.userID);
      structureFormData.append('token', this.userService.userToken);
      structureFormData.append('email', targetName);
      structureFormData.append('file_id', file_id);
      structureFormData.append('reference', file_id);
      structureFormData.append('structure', await this.cryptoService.encryptSharedStructure(updatedShareStructure, newSharedKeyResponse));

      const structureShareResponse = await this.http.post(this.structureShareApiURL, structureFormData, { responseType: 'text' }).toPromise();

      // Update the sharedWith attribute of the element
      if (!element.sharedWith)
      {
        element.sharedWith = [targetName]; // Initialize the sharedWith array if it doesn't exist
        console.log("Shared with: ", element.sharedWith);
      } else
      {
        element.sharedWith.push(targetName); // Add the target user to the sharedWith array
        console.log("Shared with: ", element.sharedWith);
      }
      this.update(element.id, { sharedWith: element.sharedWith });
      this.update(element.id, { old_file_id: file_id });
      this.update(element.id, { file_id: newFileId })
      this.update(element.id, { shared: true })

      // Delete the original file
      const deleteFormData = new FormData();
      deleteFormData.append('user_id_ext', this.userService.userID);
      deleteFormData.append('token', this.userService.userToken);
      deleteFormData.append('file_id', file_id)
      const deleteResponse = this.http.post(this.deleteApiURL, deleteFormData, {
        reportProgress: true,
        observe: 'events',
        responseType: 'text'
      });
    } else
    {
      // console.log("Sharing Folder", file_id);
      const newSharedKeyFormData = new FormData()
      newSharedKeyFormData.append('mode', 'newsharedkey');
      newSharedKeyFormData.append('user_id_ext', this.userService.userID);
      newSharedKeyFormData.append('auth1', '0');
      newSharedKeyFormData.append('auth2', '0');
      newSharedKeyFormData.append('auth3', '0');
      newSharedKeyFormData.append('user_email', targetName);
      newSharedKeyFormData.append('reference', file_id);

      const newSharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", newSharedKeyFormData, { responseType: 'text' }).toPromise();

      // Encrypt Shared File Structure
      const shareStructure = this.map.get(element.id);
      // shareStructure.old_file_id = file_id;
      const updatedShareStructure = JSON.stringify(shareStructure);
      // console.log("Folder Share Structure", shareStructure);

      const structureFormData = new FormData()
      structureFormData.append('mode', 'new');
      structureFormData.append('user_id_ext', this.userService.userID);
      structureFormData.append('token', this.userService.userToken);
      structureFormData.append('email', targetName);
      structureFormData.append('file_id', file_id);
      structureFormData.append('reference', file_id);
      structureFormData.append('structure', await this.cryptoService.encryptSharedStructure(updatedShareStructure, newSharedKeyResponse));

      const structureShareResponse = await this.http.post(this.structureShareApiURL, structureFormData, { responseType: 'text' }).toPromise();

      // Update the sharedWith attribute of the element
      if (!element.sharedWith)
      {
        element.sharedWith = [targetName]; // Initialize the sharedWith array if it doesn't exist
        console.log("Shared with: ", element.sharedWith);
      } else
      {
        element.sharedWith.push(targetName); // Add the target user to the sharedWith array
        console.log("Shared with: ", element.sharedWith);
      }

      this.update(element.id, { sharedWith: element.sharedWith });
      this.update(element.id, { shared: true })
    }
  }

  // Download the file from backend for encryption with sharedKey
  async downloadAndDecryptFile(element): Promise<File>
  {

    if (element.shared === true)
    {
      const keyFormData = new FormData()
      keyFormData.append('mode', 'getsharedkeys');
      keyFormData.append('user_id_ext', this.userService.userID);
      keyFormData.append('auth1', '0');
      keyFormData.append('auth2', '0');
      keyFormData.append('auth3', '0');
  
      const sharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, {responseType: 'text'}).toPromise()
  
      const sharedKeys = JSON.parse(sharedKeyResponse);
  
      // Filter shared keys by the reference
      const filteredSharedKeys = sharedKeys.filter((key: any) => key.reference === element.old_file_id);
  
      // Sort filtered shared keys by some criteria (for example, by their index)
      const sortedSharedKeys = filteredSharedKeys.sort((a: any, b: any) => sharedKeys.indexOf(a) - sharedKeys.indexOf(b));
  
      // Choose the last element from the sorted array (latest shared key)
      const latestSharedKey = sortedSharedKeys[sortedSharedKeys.length - 1]?.shared_key;    
  
      const formData = new FormData();
      formData.append('user_id_ext', this.userService.userID);
      formData.append('token', this.userService.userToken);
      formData.append('file_id', element.file_id);
      formData.append('file_name', "shareFile");
  
      const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();
  
      try
      {
        const encryptedBlob = downloadPostData;
        const encryptedFile = new File([downloadPostData], 'encrypted_file.hasofile', { type: encryptedBlob.type });
        return await this.cryptoService.decryptSharedFile(encryptedFile, latestSharedKey);
      }
      catch (error)
      {
        console.error('Error downloading file:', error);
        throw error;
      }
    }
    else
    {
      const formData = new FormData()
      formData.append('user_id_ext', this.userService.userID)
      formData.append('file_id', element.file_id)
      formData.append('file_name', 'shareFile')
      formData.append('token', this.userService.userToken)
      
      try
      {
        const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();
        
        const encryptedFile = new File([downloadPostData], 'encrypted_file.hasofile', { type: downloadPostData.type });
        
        return await this.cryptoService.decryptFile(encryptedFile);
      } 
      catch (error)
      {
        console.error('Error downloading and decrypting file:', error);
        throw error;
      }
    }
  }


  // Reupload the shared file with sharedKey
  async uploadSharedFile(decryptedFile: any, sharedKey: string)
  {
    const shareFormData = new FormData();
    shareFormData.append('token', this.userService.userToken);
    shareFormData.append('user_id_ext', this.userService.userID);
    shareFormData.append('datei', await this.cryptoService.encryptSharedFile(decryptedFile, sharedKey));
  
    const uploadPostData = this.http.post(this.uploadApiURL, shareFormData, {
      reportProgress: true,
      observe: 'events',
      responseType: 'text'
    });

    // return a Promise that resolves when the upload completes
    return new Promise<string>((resolve, reject) =>
    {
      uploadPostData.subscribe(
        (event: any) =>
        {
          if (event.type === HttpEventType.UploadProgress)
          {
            this.uploadProgress = Math.round((100 * event.loaded) / event.total);
          }
          else if (event.type === HttpEventType.Response)
          {
            const response: any = event.body;
            this.fileId$.next(response);
            this.fileName$.next(decryptedFile.name);
            resolve(response);
          }
        },
        (error: any) =>
        {
          console.error('Upload error:', error);
          reject(error);
        },
        () =>
        {
          console.log('<----- File uploaded ----->');
          // console.log("Map: ", this.map);
        }
      );
    });
  }

  // Download the shared File with the sharedKey
  async downloadSharedFile(p_filename: string, p_file_id: string, p_old_file_id: string)
  {
    const keyFormData = new FormData()
    keyFormData.append('mode', 'getsharedkeys');
    keyFormData.append('user_id_ext', this.userService.userID);
    keyFormData.append('auth1', '0');
    keyFormData.append('auth2', '0');
    keyFormData.append('auth3', '0');

    const sharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, {responseType: 'text'}).toPromise()

    const sharedKeys = JSON.parse(sharedKeyResponse);
    const sharedKey = sharedKeys.find((key: any) => key.reference === p_old_file_id)?.shared_key;

    // Filter shared keys by the reference
    const filteredSharedKeys = sharedKeys.filter((key: any) => key.reference === p_old_file_id);

    // Sort filtered shared keys by some criteria (for example, by their index)
    const sortedSharedKeys = filteredSharedKeys.sort((a: any, b: any) => sharedKeys.indexOf(a) - sharedKeys.indexOf(b));

    // Choose the last element from the sorted array (latest shared key)
    const latestSharedKey = sortedSharedKeys[sortedSharedKeys.length - 1]?.shared_key;

    const formData = new FormData();
    formData.append('user_id_ext', this.userService.userID);
    formData.append('token', this.userService.userToken);
    formData.append('file_id', p_file_id);
    formData.append('file_name', "shareFile");

    const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();

    try
    {
      const encryptedBlob = downloadPostData;
      const encryptedArrayBuffer = await encryptedBlob.arrayBuffer();
      const encryptedUintArray = new Uint8Array(encryptedArrayBuffer);
      const encryptedFile = new File([encryptedUintArray], p_filename + '.hasofile', { type: encryptedBlob.type });
      const decryptedFile = await this.cryptoService.decryptSharedFile(encryptedFile, latestSharedKey);

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(decryptedFile);
      downloadLink.download = p_filename;
      downloadLink.click();
      console.log("<----- File downloaded ----->");

      if (decryptedFile instanceof File) {
        decryptedFile.arrayBuffer().then(buffer => {
          // Clear the ArrayBuffer to free up memory
          buffer = null;
          // console.log("Buffer cleared");
        });
      }
    }
    catch (err)
    {
      console.error('Error downloading file:', err);
    }
  }
  /*---------------------------------------- FILESHARE FUNCTIONS END ----------------------------------------*/


  /*---------------------------------------- FILE-UPLOAD AND ENRYPTION START ----------------------------------------*/
  // run when file in FileChooser is selected, parameters for POST-Request from onFileSelected-function
  async upload(selectedfiles: any, p_user_id: string, p_token: string)
  {
    // if file is selected
    if (selectedfiles)
    {
      const formData = new FormData();
      formData.append('token', p_token);
      formData.append('user_id_ext', p_user_id);
      formData.append('datei', await this.cryptoService.encryptFile(selectedfiles));

      const uploadPostData = this.http.post(this.uploadApiURL, formData, {
        reportProgress: true,
        observe: 'events',
        responseType: 'text'
      });

      // return a Promise that resolves when the upload completes
      return new Promise<void>((resolve, reject) =>
      {
        uploadPostData.subscribe(
          (event: any) =>
          {
            if (event.type === HttpEventType.UploadProgress)
            {
              this.uploadProgress = Math.round((100 * event.loaded) / event.total);
            }
            else if (event.type === HttpEventType.Response)
            {
              const response: any = event.body;
              this.fileId$.next(response);
              this.fileName$.next(selectedfiles.name);
              resolve();
            }
          },
          (error: any) =>
          {
            console.error('Upload error:', error);
            reject(error);
          },
          () =>
          {
            this.trackerService.logEvent('File', 'Uploaded');
            console.log('<----- File uploaded ----->');
          }
        );
      });
    }
  }

  // canceled Upload
  cancelUpload()
  {
    this.uploadSub.unsubscribe();
    this.reset();
  }

  // resets UploadProgress for ProgressBar
  reset()
  {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
  /*---------------------------------------- FILE-UPLOAD AND ENRYPTION END ----------------------------------------*/


  /*---------------------------------------- FILE-DOWNLOAD AND DERYPTION START ----------------------------------------*/
  //Check if the file is shared and use correct download function
  async downloadFile(p_filename: string, p_file_id: string, p_user_id: string, p_token: string, shared: boolean, p_old_file_id: string)
  {

    if (shared === true)
    {
      const keyFormData = new FormData()
      keyFormData.append('mode', 'getsharedkeys');
      keyFormData.append('user_id_ext', this.userService.userID);
      keyFormData.append('auth1', '0');
      keyFormData.append('auth2', '0');
      keyFormData.append('auth3', '0');
  
      const sharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, {responseType: 'text'}).toPromise()
  
      const sharedKeys = JSON.parse(sharedKeyResponse);
  
      // Filter shared keys by the reference
      const filteredSharedKeys = sharedKeys.filter((key: any) => key.reference === p_old_file_id);
  
      // Sort filtered shared keys by some criteria (for example, by their index)
      const sortedSharedKeys = filteredSharedKeys.sort((a: any, b: any) => sharedKeys.indexOf(a) - sharedKeys.indexOf(b));
  
      // Choose the last element from the sorted array (latest shared key)
      const latestSharedKey = sortedSharedKeys[sortedSharedKeys.length - 1]?.shared_key;
      
      const formData = new FormData();
      formData.append('user_id_ext', this.userService.userID);
      formData.append('token', this.userService.userToken);
      formData.append('file_id', p_file_id);
      formData.append('file_name', "shareFile");
  
      const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();
  
      try
      {
        const encryptedBlob = downloadPostData;
        const encryptedArrayBuffer = await encryptedBlob.arrayBuffer();
        const encryptedUintArray = new Uint8Array(encryptedArrayBuffer);
        const encryptedFile = new File([encryptedUintArray], p_filename + '.hasofile', { type: encryptedBlob.type });
        const decryptedFile = await this.cryptoService.decryptSharedFile(encryptedFile, latestSharedKey);
  
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(decryptedFile);
        downloadLink.download = p_filename;
        downloadLink.click();
        console.log("<----- File downloaded ----->");

        if (decryptedFile instanceof File) {
          decryptedFile.arrayBuffer().then(buffer => {
            // Clear the ArrayBuffer to free up memory
            buffer = null;
            // console.log("Buffer cleared");
          });
        }
      }
      catch (err)
      {
        console.error('Error downloading file:', err);
      }
    }
    else
    {
      const formData = new FormData();
      formData.append('user_id_ext', p_user_id);
      formData.append('token', p_token);
      formData.append('file_id', p_file_id);
      formData.append('file_name', p_filename);

      const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();

      try
      {
        const encryptedBlob = downloadPostData;
        const encryptedArrayBuffer = await encryptedBlob.arrayBuffer();
        const encryptedUintArray = new Uint8Array(encryptedArrayBuffer);
        const encryptedFile = new File([encryptedUintArray], p_filename + '.hasofile', { type: encryptedBlob.type });
        const decryptedFile = await this.cryptoService.decryptFile(encryptedFile);

        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(decryptedFile);
        downloadLink.download = p_filename;
        downloadLink.click();
        this.trackerService.logEvent('File', 'Downloaded');
        console.log("<----- File downloaded ----->");

        if (decryptedFile instanceof File) {
          decryptedFile.arrayBuffer().then(buffer => {
            // Clear the ArrayBuffer to free up memory
            buffer = null;
            // console.log("Buffer cleared");
          });
        }
      }
      catch (err)
      {
        console.error('Error downloading file:', err);
      }
    }
  }
  /*---------------------------------------- FILE-DOWNLOAD AND DERYPTION END ----------------------------------------*/


  /*---------------------------------------- FOLDER-DOWNLOAD AND DERYPTION START ----------------------------------------*/
  async downloadFolder(p_folderName: string, p_folder_id, p_user_id: string, p_token: string)
  {
    const zip = new JSZip();
    const files = [];

    for (const [file_id, file] of this.map.entries())
    {
      if (file.parent === p_folder_id)
      {
        files.push(file);
      }
    }

    // Loop through each file in the folder and add it to the zip file
    for (const file of files)
    {

      if (file.shared === true)
      {
        const keyFormData = new FormData()
        keyFormData.append('mode', 'getsharedkeys');
        keyFormData.append('user_id_ext', this.userService.userID);
        keyFormData.append('auth1', '0');
        keyFormData.append('auth2', '0');
        keyFormData.append('auth3', '0');

        const sharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, { responseType: 'text' }).toPromise()
        const sharedKeys = JSON.parse(sharedKeyResponse);

        // Filter shared keys by the reference
        const filteredSharedKeys = sharedKeys.filter((key: any) => key.reference === file.old_file_id);

        // Sort filtered shared keys by some criteria (for example, by their index)
        const sortedSharedKeys = filteredSharedKeys.sort((a: any, b: any) => sharedKeys.indexOf(a) - sharedKeys.indexOf(b));

        // Choose the last element from the sorted array (latest shared key)
        const latestSharedKey = sortedSharedKeys[sortedSharedKeys.length - 1]?.shared_key;

        const formData = new FormData();
        formData.append('user_id_ext', this.userService.userID);
        formData.append('token', this.userService.userToken);
        formData.append('file_id', file.file_id);
        formData.append('file_name', "shareFile");

        const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();

        try
        {
          const encryptedBlob = downloadPostData;
          const encryptedArrayBuffer = await encryptedBlob.arrayBuffer();
          const encryptedUintArray = new Uint8Array(encryptedArrayBuffer);
          const encryptedFile = new File([encryptedUintArray], file.name + '.hasofile', { type: encryptedBlob.type });
          const decryptedFile = await this.cryptoService.decryptSharedFile(encryptedFile, latestSharedKey);

          // Add the decrypted file to the zip file
          zip.file(file.name, decryptedFile);

          if (decryptedFile instanceof File) {
            decryptedFile.arrayBuffer().then(buffer => {
              // Clear the ArrayBuffer to free up memory
              buffer = null;
              // console.log("Buffer cleared");
            });
          }
        }
        catch (err)
        {
          console.error('Error downloading file:', err);
        }
      } else
      {

        const formData = new FormData();
        formData.append('user_id_ext', p_user_id);
        formData.append('token', p_token);
        formData.append('file_id', file.file_id);
        formData.append('file_name', file.filename);

        const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();

        try
        {
          const encryptedBlob = downloadPostData;
          const encryptedArrayBuffer = await encryptedBlob.arrayBuffer();
          const encryptedUintArray = new Uint8Array(encryptedArrayBuffer);
          const encryptedFile = new File([encryptedUintArray], file.name + '.hasofile', { type: encryptedBlob.type });
          const decryptedFile = await this.cryptoService.decryptFile(encryptedFile);

          // Add the decrypted file to the zip file
          zip.file(file.name, decryptedFile);

          if (decryptedFile instanceof File) {
            decryptedFile.arrayBuffer().then(buffer => {
              // Clear the ArrayBuffer to free up memory
              buffer = null;
              // console.log("Buffer cleared");
            });
          }
        } catch (err)
        {
          console.error('Error downloading file:', err);
        }
      }
    }

    // Generate zip file and create a download link
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const url = window.URL.createObjectURL(zipBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = p_folderName + '.zip';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.trackerService.logEvent('Folder', 'Downloaded');
    console.log("<----- Folder downloaded ----->");
  }

  async downloadSharedFolder(p_folderName: string, p_folder_id: string)
  {
    const zip = new JSZip();
    const files = [];

    for (const [file_id, file] of this.resultMap.entries())
    {
      if (file.parent === p_folder_id)
      {
        files.push(file);
      }
    }

    const keyFormData = new FormData()
    keyFormData.append('mode', 'getsharedkeys');
    keyFormData.append('user_id_ext', this.userService.userID);
    keyFormData.append('auth1', '0');
    keyFormData.append('auth2', '0');
    keyFormData.append('auth3', '0');

    const sharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, {responseType: 'text'}).toPromise()
    const sharedKeys = JSON.parse(sharedKeyResponse);

    // Loop through each file in the folder and add it to the zip file
    for (const file of files)
    {
      // Filter shared keys by the reference
      const filteredSharedKeys = sharedKeys.filter((key: any) => key.reference === file.old_file_id);
      const sortedSharedKeys = filteredSharedKeys.sort((a: any, b: any) => sharedKeys.indexOf(a) - sharedKeys.indexOf(b));
  
      // Choose the last element from the sorted array (latest shared key)
      const latestSharedKey = sortedSharedKeys[sortedSharedKeys.length - 1]?.shared_key;    
  
      const formData = new FormData();
      formData.append('user_id_ext', this.userService.userID);
      formData.append('token', this.userService.userToken);
      formData.append('file_id', file.file_id);
      formData.append('file_name', file.name);
  
      const downloadPostData = await this.http.post(this.downloadApiURL, formData, { responseType: 'blob' }).toPromise();
  
      try
      {
        const encryptedBlob = downloadPostData;
        const encryptedArrayBuffer = await encryptedBlob.arrayBuffer();
        const encryptedUintArray = new Uint8Array(encryptedArrayBuffer);
        const encryptedFile = new File([encryptedUintArray], file.filename + '.hasofile', { type: encryptedBlob.type });
        const decryptedFile = await this.cryptoService.decryptSharedFile(encryptedFile, latestSharedKey);
  
        zip.file(file.name, decryptedFile);

        if (decryptedFile instanceof File) {
          decryptedFile.arrayBuffer().then(buffer => {
            // Clear the ArrayBuffer to free up memory
            buffer = null;
            // console.log("Buffer cleared");
          });
        }
      }
      catch (err)
      {
        console.error('Error downloading file:', err);
      }
    }

    // Generate zip file and create a download link
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    const url = window.URL.createObjectURL(zipBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = p_folderName + '.zip';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.trackerService.logEvent('Folder', 'Downloaded');
    console.log("<----- Shared Folder downloaded ----->");
  }
  /*---------------------------------------- FOLDER-DOWNLOAD AND DERYPTION END ----------------------------------------*/


  /*---------------------------------------- FILE-STRUCTURE UPDATE START ----------------------------------------*/
  // Update the File Structure to the Backend
  updateFileStructure(p_user_id: string, p_token: string)
  {
    const formData = new FormData();
    formData.append('token', p_token);
    formData.append('user_id_ext', p_user_id);
    formData.append('mode', 'update');

    const jsonMap = JSON.stringify(Array.from(this.map.entries()));
    const encryptedJsonMap = this.cryptoService.encryptStructure(jsonMap);
    formData.append('structure_data', encryptedJsonMap);


    this.http.post(this.structureApiURL, formData).subscribe((response) =>
    {
      // console.log("<----- updateFileStructure successful ----->");
    });
  }
  /*---------------------------------------- FILE-STRUCTURE UPDATE END ----------------------------------------*/


  /*---------------------------------------- FILE-STRUCTURE GET START ----------------------------------------*/
  // Method to update the file element map
  updateFileElementMap(resultMap: Map<string, FileElement>)
  {
    this.resultMap = resultMap;
  }

  // Method to retrieve the file element map
  getFileElementMap(): Map<string, FileElement>
  {
    return this.resultMap;
  }

  // Get the FileStructure from the Backend
  getFileStructure(p_user_id: string, p_token: string): Observable<string>
  {
    const formData = new FormData();
    formData.append('token', p_token);
    formData.append('user_id_ext', p_user_id);
    formData.append('mode', 'get');
    formData.append('structure_data', '1');
    return this.http.post(this.structureApiURL, formData, { responseType: 'text' }).pipe(
      map( (encryptedResponse: string) =>
      {
        const decryptedResponse = this.cryptoService.decryptStructure(encryptedResponse);
        return decryptedResponse;
      }),
    );
  }

  // Get the shared Structures from the backend
  async getSharedStructure(): Promise<string> {

    const keyFormData = new FormData()
    keyFormData.append('mode', 'getsharedkeys');
    keyFormData.append('user_id_ext', this.userService.userID);
    keyFormData.append('auth1', '0');
    keyFormData.append('auth2', '0');
    keyFormData.append('auth3', '0');

    const sharedKeyResponse = await this.http.post("https://nssks.impact-tec.com/nssks.php", keyFormData, {responseType: 'text'}).toPromise()

    const sharedKeys = JSON.parse(sharedKeyResponse);

    const formData = new FormData();
    formData.append('mode', 'get');
    formData.append('user_id_ext', this.userService.userID);
    formData.append('token', this.userService.userToken);
    formData.append('email', '1');
    formData.append('file_id', '1');
    formData.append('reference', '1');
    formData.append('structure', '1');


  
    const structureResponse = await this.http.post(this.structureShareApiURL, formData, { responseType: 'text' }).toPromise();

    if (!structureResponse) {
      return null;
    }
  
    const jsonData = JSON.parse(structureResponse);


    const sharedKeyMap = {};
    sharedKeys.forEach((keyObject) =>
    {
      const fileId = keyObject.reference;
      sharedKeyMap[fileId] = keyObject.shared_key;
    });

    const referenceArray = jsonData.reference_shared;
    const encryptedStrings = jsonData.structure_shared;
  
    const encryptedStringMap = {};
    encryptedStrings.forEach((encryptedString, index) => {
      const referenceValue = referenceArray[index];
      if (referenceValue && referenceValue !== '') {
        encryptedStringMap[referenceValue] = encryptedString;
      }
    });

    const resultMap = [];

    Object.keys(encryptedStringMap).forEach((referenceValue) => {
      const encryptedString = encryptedStringMap[referenceValue];
      const sharedKey = sharedKeyMap[referenceValue];
  
      const decryptedString = this.cryptoService.decryptSharedStructure(encryptedString, sharedKey);
  
      const fileElement = JSON.parse(decryptedString) as FileElement;
      const Id = fileElement.id || v4();
      resultMap.push([Id, fileElement]);
    });
    
    const resultMapString = JSON.stringify(resultMap);
    return resultMapString;
  
  }
  /*---------------------------------------- FILE-STRUCTURE GET END ----------------------------------------*/
}
