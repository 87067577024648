import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { FileService } from '../../services/file.service'

@Component({
  selector: 'app-new-folder-dialog',
  templateUrl: './new-folder-dialog.component.html',
  styleUrls: ['./new-folder-dialog.component.scss']
})
export class NewFolderDialogComponent
{
  constructor(
    public dialogRef: MatDialogRef<NewFolderDialogComponent>,
    public fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  //Variables
  folderName: string;
  folderExists: boolean = false;

  // check if a folder with the name already exists
  folderNameChanged(newFolderName: string)
  {
    this.folderExists = this.fileService.checkIfElementExists(newFolderName, this.data.parentId)
  }

  // OnInit Function
  ngOnInit() { }
}
