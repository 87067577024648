<!-- Title -->
<mat-toolbar>
  <h1 mat-dialog-title>Datei teilen</h1>
  <span class="vertical-spacer"></span>
  <button mat-icon-button mat-dialog-close>
    <i class="fa-light fa-circle-xmark"></i>
  </button>
</mat-toolbar>

<!-- Input for target username -->
<mat-dialog-content>
  <mat-form-field class="example-full-width dialogContent">
    <input matInput placeholder="E-Mail-Adresse" [(ngModel)]="targetName" />
  </mat-form-field>
</mat-dialog-content>

<!-- Buttons for Actions -->
<mat-dialog-actions>
  <!-- Cancel Action to create a new folder -->
  <button mat-raised-button mat-dialog-close>Abbrechen</button>
  <!-- Share the file with entered target username -->
  <button mat-raised-button (click)="shareFile(targetName)" color="primary">Teilen</button>
</mat-dialog-actions>